import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';
import { useBoolean, useSafeState } from 'ahooks';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';
import { apiHooks } from '@/hooks/apis';
import { ArrElement } from '@/utils/array-element.type';
import { CreateProjectPanelValue } from '..';
import { Buttons, ButtonsProps } from '../buttons';
import { IRSelector } from './ir-selector';
import { PodSelector } from './pod-selector';
import { WarehouseSelector } from './warehouse-selector';

export interface MBLPanelProps extends Pick<ButtonsProps, 'goPre'> {
	projectName: string;
	values: Partial<CreateProjectPanelValue>;
	createLoading: boolean;
	onChange: (value: CreateProjectPanelValue['containers']) => void;
	onClick2: () => void;
}

export const MBLPanel = ({
	projectName,
	values,
	createLoading,
	onChange,
	onClick2,
	goPre,
}: MBLPanelProps) => {
	const { t } = useTranslation();

	const value = values['containers'];

	const selectedIds = value.map((v) => v?.id);

	const { runAsync, data } = apiHooks.useSearchOwnMBLNumbers();

	const [selectedPod, setSelectedPod] = useSafeState<number>();
	const [selectedIR, setSelectedIR] = useSafeState<number>();

	const [selectedWarehouse, setSelectedWarehouse] = useSafeState<number>();

	const [isAllSelected, { toggle: toggleAllSelected }] = useBoolean();

	const handleAllClick = () => {
		if (isAllSelected) {
			onChange(value.filter((v) => !data.map((d) => d.id).includes(v.id)));
		} else {
			onChange([
				...value,
				...data
					.filter((d) => !selectedIds.includes(d.id))
					.map((d) => ({
						id: d.id,
						number: d.number,
						mbl_number: d.mbl_number,
						port_of_discharge: {
							id: d.port_of_discharge.id,
							name: d.port_of_discharge.name,
						},
						final_port: {
							id: d.final_port.id,
							name: d.final_port.name,
						},
						warehouse: {
							id: d.warehouse.id,
							name: d.warehouse.name,
						},
					})),
			]);
		}
		toggleAllSelected();
	};

	const handleItemClick = (record: ArrElement<CreateProjectPanelValue['containers']>) => {
		const isSelected = selectedIds.includes(record?.id);

		if (isSelected) {
			onChange(value.filter((v) => v?.id !== record.id));
		} else {
			onChange([
				...value,
				{
					id: record.id,
					number: record.number,
					mbl_number: record.mbl_number,
					port_of_discharge: {
						id: record?.port_of_discharge?.id,
						name: record?.port_of_discharge.name,
					},
					final_port: {
						id: record.final_port.id,
						name: record.final_port.name,
					},
					warehouse: {
						id: record.warehouse.id,
						name: record.warehouse.name,
					},
				},
			]);
		}
	};

	const currentSelected = data.filter((d) => selectedIds.includes(d.id));

	const columns: TableProps<ArrElement<CreateProjectPanelValue['containers']>>['columns'] = [
		{
			title: '',
			dataIndex: '',
			type: 'extra',
			width: '20px',
			HeaderRender: ({ style, className }) => (
				<div style={style} className={`${className} p-[6px]`}>
					<Checkbox
						checked={isAllSelected}
						indeterminate={
							!isAllSelected && currentSelected.length > 0 && currentSelected.length < data.length
						}
						onClick={handleAllClick}
					/>
				</div>
			),
			CellRender: ({ record }) => (
				<div className='p-[6px]'>
					<Checkbox
						checked={selectedIds.includes(record.id)}
						onClick={() => {
							handleItemClick(record);
						}}
					/>
				</div>
			),
		},
		{
			title: t('MBL'),
			dataIndex: 'mbl_number',
		},
		{
			title: t('Container'),
			dataIndex: 'number',
		},
		{
			title: t('Pod'),
			dataIndex: ['port_of_discharge', 'name'],
		},
		{
			title: t('IR City'),
			dataIndex: ['final_port', 'name'],
		},
		{
			title: t('Warehouse'),
			dataIndex: ['warehouse', 'name'],
		},
	];

	const dataSource = (data ?? []).filter(
		(d) =>
			(!selectedPod || d?.port_of_discharge.id === selectedPod) &&
			(!selectedIR || d?.final_port.id === selectedIR) &&
			(!selectedWarehouse || d?.warehouse?.id === selectedWarehouse),
	);

	return (
		<>
			<div className='w-full h-full flex flex-col overflow-hidden'>
				<div className='h2-b text-black'>{projectName}</div>

				<Gap height='28px' />

				{/* content */}
				<div className=' box-border grow p-[16px] flex flex-col overflow-hidden border-[1px] border-solid border-grey-02 rounded-[8px]'>
					{/* title */}
					<div className='body-3-b text-grey-01'>{t('Multi-Select Your Own MBLs')}</div>

					<Gap height='16px' />

					<div>
						<div className='body-4-r text-black'>{t(`Search`)}</div>
						<Input
							placeholder={t(`Search for MBL, Container Number`)}
							onChange={(e) => {
								if (e.target.value.length < 2) return;
								runAsync({
									params: {
										query: e.target.value,
									},
								});
							}}
						/>
					</div>

					<Gap height='12px' />

					<div className='flex items-center'>
						<div className='grow w-1'>
							<div className='body-4-r text-black'>{t(`POD LA`)}</div>
							<PodSelector onSelect={(v) => setSelectedPod(v)} />
						</div>
						<Gap width='8px' />
						<div className='grow w-1'>
							<div className='body-4-r text-black'>{t(`IR City`)}</div>
							<IRSelector onSelect={(v) => setSelectedIR(v)} />
						</div>
						<Gap width='8px' />
						<div className='grow w-1'>
							<div className='body-4-r text-black'>{t(`Warehouse`)}</div>
							<WarehouseSelector onSelect={(v) => setSelectedWarehouse(v)} />
						</div>
					</div>

					<Gap height='16px' />

					<Table
						columns={columns}
						dataSource={dataSource}
						commonHeaderClass='sticky top-0 z-[2] bg-grey-05 foot-note-1-r text-grey-01'
						commonCellClass='h-[38px] flex items-center'
						commonBodyClass='foot-note-2-r text-grey-01'
						className=' relative grow overflow-scroll '
					/>
				</div>

				<Gap height='22px' />

				<Buttons
					text2={t(`Done`)}
					preventDefaultClick2
					goPre={goPre}
					onClick2={onClick2}
					button2Props={{ loading: createLoading }}
				/>
			</div>
		</>
	);
};
