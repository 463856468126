import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Popover, Space } from 'antd';
import { useGlobalContext } from '@/services/contexts';
import { ModalNewApiToken } from '@/pages/api/api-token';

interface LogoutProps {
	children: React.ReactNode;
}

export default ({ children }: LogoutProps) => {
	const { t } = useTranslation();

	const { userContext } = useGlobalContext();
	const info = userContext.getUserInfo();
	return (
		<Popover
			content={
				<Space direction='vertical'>
					{info && info.api_v2_enabled && <ModalNewApiToken />}
					{info && info.api_v2_enabled && (
						<Link to='/apidoc/v2'>
							<Button type='text'>{t('API V2 Doc')}</Button>
						</Link>
					)}
					{info && info.api_v3_enabled && (
						<Link to='/apidoc/v3'>
							<Button type='text'>{t('API V3 Doc')}</Button>
						</Link>
					)}
					<Button type='text' onClick={() => userContext.logout()}>
						{t('Logout')}
					</Button>
				</Space>
			}
			placement='right'
		>
			{children}
		</Popover>
	);
};
