import { RequestedRateContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import RateRequestTable from './components/rate-request-table';
import SearchForm from './components/search-form';
import Panel from '@/components/panel';

const RequestedRateApp = observer(() => {
	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen'>
			<Panel classNames='h-full flex flex-col'>
				{/* search form */}
				<div>
					<SearchForm />
				</div>
				{/* table */}
				<div className='flex-grow pl-[32px] pr-[32px] h-[calc(100%_-_98px)]'>
					<RateRequestTable />
				</div>
			</Panel>
		</div>
	);
});

export default observer(() => {
	return (
		<RequestedRateContext>
			<RequestedRateApp />
		</RequestedRateContext>
	);
});
