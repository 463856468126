import { useEffect, useRef, useState } from 'react';
import { Button, Card, Space, Table, TableProps } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import Link from 'antd/es/typography/Link';
import { PrepayOrder } from '@/interfaces/prepay-order.interface';
import { getDocUrl } from '@/services/apis';
import { usePrepayOrderContext } from '@/services/contexts/prepay-order';
import '@/styles/custom-table.less';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Panel from '@/components/panel';
import usePrepayOrderDrawer from '../hooks/prepay-order-drawer';
import usePrepayOrderQs from './use-prepay-order-params';

const SORT_MAP = {
	ascend: 'asc',
	descend: 'desc',
};

interface PreOrderTableProps {
	tableProps?: TableProps<PrepayOrder>;
}

const NumberCard = ({ title, number }) => {
	return (
		<Card style={{ width: '16vw' }}>
			<strong>{title}</strong>
			<h3>$ {new Intl.NumberFormat('en-US').format(number)}</h3>
		</Card>
	);
};

const PrepayOrderTable = observer(({ tableProps }: PreOrderTableProps) => {
	const orderContext = usePrepayOrderContext();

	const { openDrawer, Drawer: PreorderDrawer } = usePrepayOrderDrawer();

	const { parsedSearchParams } = usePrepayOrderQs();

	const [paginationPage, setPaginationPage] = useState({
		total: 0,
		pageSize: 25,
		current: 1,
	});

	const showTotal = (total) => `${t('Total')} ${total}`;

	const fetchIndexRef = useRef(0);

	const orders = orderContext.prepayOrders;

	const handlePreview = async (file: any, target: any) => {
		const resp = await getDocUrl(file.id, 'prepay_documents', target.id);
		const url = resp.data;
		const w = window.open(url, '_blank');
	};

	const columns: TableProps<PrepayOrder>['columns'] = [
		{
			key: 'order_number',
			title: t('Order Number'),
			render(_, record) {
				return <div>{record.order_number}</div>;
			},
		},
		{
			key: 'prepaid_amount',
			dataIndex: ['prepaid_amount'],
			title: t('Prepaid Amount'),
		},
		{
			key: 'prepaid_currency',
			dataIndex: ['prepaid_currency'],
			title: t('Prepaid Currency'),
		},
		{
			key: 'exchange_rate',
			dataIndex: ['exchange_rate'],
			title: t('Exchange Rate'),
		},
		{
			key: 'exchange_rate_cost',
			dataIndex: ['exchange_rate_cost'],
			title: t('Exchange Rate Cost'),
		},
		{
			key: 'amount',
			dataIndex: ['amount'],
			title: t('Amount'),
		},
		{
			key: 'bank_name',
			dataIndex: ['bank_name'],
			title: t('Bank Name'),
		},
		{
			key: 'status_name',
			dataIndex: ['status_name'],
			title: t('Status'),
			render: (v) => t(v),
		},
		{
			title: t('Doc of receipt'),
			dataIndex: 'docs',
			width: 150,
			render: (v, record) => {
				return (
					<>
						{record.documents.map((doc: any) => (
							<Link key={doc.id} onClick={() => handlePreview(doc, record)}>
								{doc.file_name}
							</Link>
						))}
					</>
				);
			},
		},
		{
			key: 'created_at',
			dataIndex: ['created_at'],
			title: t('Order Date'),
		},
		// Table.EXPAND_COLUMN,
	];

	const handleChange: TableProps<PrepayOrder>['onChange'] = (
		pagination,
		filter,
		sorter: SorterResult<PrepayOrder>,
	) => {
		const { current, pageSize } = pagination;

		const values = {
			...parsedSearchParams,
			page: current,
			per_page: pageSize,
		};

		const sort_value = SORT_MAP[sorter.order as keyof typeof SORT_MAP] || '';

		const sortValues = sort_value
			? {
					sort_value,
					sort_by: sorter.columnKey,
			  }
			: {};

		handleSearch({ ...values, ...sortValues });
	};

	const handleSearch = async (values) => {
		fetchIndexRef.current += 1;
		const fetchIndex = fetchIndexRef.current;
		orderContext.isFetchingPrepayOrders = true;

		try {
			const res = await orderContext.fetchPrepayOrders({
				params: {
					...values,
				},
			});

			if (fetchIndex === fetchIndexRef.current) {
				orderContext.prepayOrders = res.data.data;

				const isErrorPagination =
					res.data.meta.current_page !== 1 && (res.data.data ?? []).length === 0;

				const newPagination = isErrorPagination
					? {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: 1,
					  }
					: {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: res.data.meta.current_page,
					  };

				setPaginationPage(newPagination);
			}
		} finally {
			orderContext.isFetchingPrepayOrders = false;
		}
	};

	useEffect(() => {
		handleSearch(parsedSearchParams);
		orderContext.fetchAccount({ autoSet: true });
	}, []);

	return (
		<div className='h-full'>
			<div className='flex justify-between items-center'>
				<div className='h3-b'>{t('Wallet')}</div>
				<Button type='primary' onClick={() => openDrawer()}>
					{t('Add Funds')}
				</Button>
			</div>
			<Space size='middle' className='w-full mt-[16px]' wrap={true}>
				<NumberCard title={t('Current Balance')} number={orderContext.account?.balance || 0} />
				<NumberCard
					title={t('Prepaid Amount')}
					number={orderContext.account?.prepaid_amount || 0}
				/>
				<NumberCard title={t('Hold Amount')} number={orderContext.account?.hold_amount || 0} />
				<NumberCard
					title={t('Settled Amount')}
					number={orderContext.account?.settled_amount || 0}
				/>
			</Space>
			<br />
			<br />
			<Panel classNames='h-[calc(100%_-_206px)]'>
				{/* <Header onSearch={handleSearch} /> */}
				<div className='h-[calc(100%_-_46px)]'>
					<Table
						{...(tableProps ?? {})}
						className='custom-table auto-h-full-table'
						loading={orderContext.isFetchingPrepayOrders}
						rowKey='id'
						columns={columns}
						dataSource={orders}
						pagination={{ position: ['bottomCenter'], ...paginationPage, showTotal }}
						onChange={handleChange}
					/>
				</div>
			</Panel>
			{PreorderDrawer}
		</div>
	);
});

export default PrepayOrderTable;
