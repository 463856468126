import { useState } from 'react';
import { Button, Checkbox, Popover } from 'antd';
import { Container } from '@/interfaces/order.interface';
import { FilterOutlined } from '@ant-design/icons';
import { t } from 'i18next';

export default () => {
	const [selectedKeys, setSelectedKeys] = useState<string[]>([
		'Blocked',
		'In Transit',
		'Completed',
		'Cancelled',
	]);

	const [open, setOpen] = useState(false);

	const clear = () => setSelectedKeys([]);

	const handleSelected = (key: string) => {
		let newSelectedKeys = [];
		if (selectedKeys.includes(key)) {
			newSelectedKeys = selectedKeys.filter((k) => k !== key);
		} else {
			newSelectedKeys = [...selectedKeys, key];
		}
		setSelectedKeys([...newSelectedKeys]);
	};

	const handleOpen = (open: boolean) => {
		setOpen(open);
	};

	const FilterContent = ({ containers }: { containers: Container[] }) => {
		const blockedNum = containers.filter((c) => c.status_text === 'blocked').length;
		const transitNum = containers.filter((c) => c.status_text === 'in_transit').length;
		const completedNum = containers.filter((c) => c.status_text === 'completed').length;
		const cancelledNum = containers.filter((c) => c.status_text === 'cancelled').length;

		const items = [
			{
				label: t('Blocked'),
				num: blockedNum,
				color: 'bg-updated-red',
				value: 'Blocked',
			},
			{
				label: t('In Transit'),
				num: transitNum,
				color: 'bg-primary-regular',
				value: 'In Transit',
			},
			{
				label: t('Completed'),
				num: completedNum,
				color: 'bg-updated-green',
				value: 'Completed',
			},
			{
				label: t('Cancelled'),
				num: cancelledNum,
				color: 'bg-grey-01',
				value: 'Cancelled',
			},
		];

		return (
			<div className='w-[216px] p-[0_4px_0_4px]'>
				<div className='mb-[5px] flex justify-between items-center'>
					<span className='body-4-m'>{t('Filter')}</span>
					<Button type='text' className='foot-note-2-b text-primary-regular' onClick={clear}>
						{t('Clear')}
					</Button>
				</div>
				{items.map((i) => (
					<div className='flex p-[8px_12px_0_0]'>
						<Checkbox
							className=' w-[24px]'
							checked={selectedKeys.includes(i.value)}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								handleSelected(i.value);
							}}
						/>{' '}
						<div className='flex items-center'>
							<div
								className={`mr-[8px] w-[7px] h-[7px] ${i.color} rounded-[50%] body-3-r text-grey-01`}
							/>
							{i.label}
							{` (${i.num})`}
						</div>
					</div>
				))}
			</div>
		);
	};

	const Filter = ({ containers }: { containers: Container[] }) => (
		<Popover
			trigger='click'
			content={<FilterContent containers={containers} />}
			placement='bottom'
			open={open}
			onOpenChange={handleOpen}
		>
			<Button
				icon={<FilterOutlined />}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					handleOpen(true);
				}}
			/>
		</Popover>
	);

	return {
		selectedKeys,
		Filter,
	};
};
