import { useNavigate } from 'react-router-dom';
import { Button, message, Tooltip } from 'antd';
import DraftOrderDestinationIcon from '@/icons/draft-order-destination-icon';
import DraftOrderPortIcon from '@/icons/draft-order-port-icon';
import { DraftOrder } from '@/interfaces/draft-order.interface';
import { isOrderExpired } from '@/services/apis';
import clsx from 'clsx';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

interface DraftOrderContainerProps {
	draftOrder: DraftOrder;
	className?: string;
}

interface ItemRenderProps {
	iconTitle: string;
	icon: React.ReactNode;
	iconText: string;
	secondTitle: string;
	secondText: string;
}

const ItemRender = observer(
	({ iconTitle, icon, iconText, secondTitle, secondText }: ItemRenderProps) => {
		return (
			<div className='pb-[20px] w-[147px] flex-grow-0 flex-shrink-0'>
				<div className='mb-[3px] foot-note-4-10px'>{iconTitle}</div>

				<div className='mb-[18px] flex items-center'>
					<div className='mr-[7px]'>{icon}</div>
					<div className='font-[600] text-[16px] text-[#000000]'>{iconText}</div>
				</div>

				<div className='foot-note-4-10px'>{secondTitle}</div>
				<div className=''>{secondText}</div>
			</div>
		);
	},
);

export default observer(({ draftOrder, className }: DraftOrderContainerProps) => {
	const navigate = useNavigate();

	const onClickEditDraftOrder = async (e) => {
		e.stopPropagation();
		e.preventDefault();

		try {
			const res = await isOrderExpired({ path: { orderId: draftOrder.id } });
			const is_expired = res.data.is_expired;
			if (!is_expired) {
				navigate(`/search-rate/book?draftOrderId=${draftOrder.id}`);
			} else {
				message.error(t('The rate for this draft has expired. Not editable.'));
			}
		} catch (error: any) {
			message.error(t('Open edit draft order error'));
			console.error('draft error', error);
		}
	};

	return (
		<div
			className={clsx(
				className,
				'p-[17px_12px_0_12px] w-[21.5rem] min-h-fit max-h-full flex flex-col justify-between bg-grey-06 rounded-[6px] shadow-[0px_6px_18px_0px_#8F95B240]',
			)}
		>
			<div className='mb-[20px] flex-grow grid grid-cols-[repeat(2,_1fr)] grid-rows-[17px_1fr_33px]'>
				{[t('Port'), t('Destination')].map((text) => (
					<div className='mb-[3px] foot-note-4-10px'>{text}</div>
				))}

				{[
					{
						icon: <DraftOrderPortIcon />,
						iconText: draftOrder.port_of_discharge.name,
					},
					{
						icon: <DraftOrderDestinationIcon />,
						iconText: draftOrder.final_port.name,
					},
				].map(({ icon, iconText }) => (
					<div className='mb-[18px] flex items-center'>
						<div className='mr-[7px]'>{icon}</div>
						<div className='font-[600] text-[16px] text-[#004A99]'>{iconText}</div>
					</div>
				))}

				{[
					{
						secondTitle: t('POD ETA'),
						secondText: draftOrder.port_of_discharge_eta,
					},
					{
						secondTitle: t('Number of containers'),
						secondText: String(draftOrder.container_count ?? 0),
					},
				].map(({ secondTitle, secondText }) => (
					<div>
						<div className='foot-note-4-10px'>{secondTitle}</div>
						<div className='body-4-m'>{secondText}</div>
					</div>
				))}
			</div>

			<div>
				<div className='mb-[14px] h-[1px] bg-grey-04' />

				{/* bottom */}
				<div className='mb-[10px] flex justify-between items-center'>
					{/* left */}
					<div>
						<div className='font-[700] text-[20px] text-primary-dark-02'>
							${draftOrder.sellRate?.total_rate}
						</div>
						<div>
							{`${t('Pricing valid until')} `}
							{draftOrder.sellRate?.expired_at}
						</div>
					</div>
					{/* right */}

					{draftOrder.is_expired ? (
						<Tooltip title={t('The rate for this draft has expired. Not editable.')}>
							<Button disabled type='primary'>
								{t('Edit')}/{t('Order')}
							</Button>
						</Tooltip>
					) : (
						<Button type='primary' onClick={onClickEditDraftOrder}>
							{t('Edit')}/{t('Order')}
						</Button>
					)}
				</div>

				{/* bottom border */}
				<div className=' border-[2px] border-solid border-primary-light-01 rounded-[2px]' />
			</div>
		</div>
	);
});
