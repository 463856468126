import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { Order } from '@/interfaces/order.interface';
import clsx from 'clsx';
import { t } from 'i18next';
import { uniq } from 'lodash';
import { observer } from 'mobx-react-lite';

interface ItemRenderProps {
	title: string;
	content: Record<string, React.ReactNode>;
	className?: string;
}

interface OrderDetailsProps {
	order: Order;
}

const ItemRender = observer(({ title, content, className }: ItemRenderProps) => {
	return (
		<div
			className={clsx(
				'p-[24px] rounded-[4px] overflow-hidden shadow-[0px_4px_10px_0px_#0000000D] bg-white',
				className,
			)}
		>
			{/* title */}
			<div className='mb-[10px] body-3-m leading-[27px] border-t-0 border-l-0 border-r-0 border-b-[1px] border-solid border-grey-04'>
				{title}
			</div>

			{/* content */}
			<div className='grid grid-cols-[140px_1fr]'>
				{Object.entries(content).map(([k, v]) => (
					<>
						<div className='mb-[14px] body-4-m'>{k}</div>
						<span className='body-4-r text-grey-01'>{v ?? t('N/A')}</span>
					</>
				))}
			</div>
		</div>
	);
});

export default observer(({ order }: OrderDetailsProps) => {
	const {
		id,
		mbl_number,
		commodity,
		customer_memo,
		terminal,
		warehouse,
		port_of_discharge,
		final_port,
		live_or_drop,
		containers,
	} = order;

	const orderInfoRecord = {
		[t('Order #')]: id,
		[t('MBL #')]: mbl_number,
		[t('Commodity')]: commodity,
		[t('Customer Memo')]: customer_memo,
	};

	const contactInfoRecord = {
		[t('Contact Name')]: t('N/A'),
		[t('Email')]: t('N/A'),
	};

	const routeInfoRecord = {
		[t('Pickup from')]: terminal?.delivery_order_address ?? '',
		[t('Delivery to')]: warehouse?.delivery_order_address,
		[t('Pod')]: port_of_discharge?.name,
		[t('Final Port') + '/' + t('Ramp')]: final_port?.name,
		[t('Delivery Type')]: uniq(
			(containers || []).map((container) => {
				return container.live_or_drop === LiveOrDrop.Empty
					? ''
					: container.live_or_drop === LiveOrDrop.Live
					? t('Live')
					: t('Drop');
			}),
		)
			.filter((i) => i)
			.join(','),
	};

	return (
		<div className='flex justify-between'>
			{/* left */}
			<div className='flex-grow w-1 pr-[50px]'>
				{/* order info */}
				<ItemRender title={t('Order Info')} content={orderInfoRecord} className='mb-[20px]' />

				{/* contact info */}
				{/* <ItemRender title={t('Contact Info')} content={contactInfoRecord} /> */}
			</div>

			{/* right */}
			{/* route info */}
			<div className='flex-grow w-1'>
				<ItemRender title={t('Route Info')} content={routeInfoRecord} />
			</div>
		</div>
	);
});
