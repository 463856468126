import { useEffect, useRef, useState } from 'react';
import { Space, Table, TableProps, Tag, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import DiversionIcon from '@/icons/diversion-icon';
import DiversionOutlinedIcon from '@/icons/diversion-outlined-icon';
import { Container, Order } from '@/interfaces/order.interface';
import { useGlobalContext, useOrderContext } from '@/services/contexts';
import '@/styles/custom-table.less';
import { InfoCircleFilled, InfoCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ExpandIcon } from '@/components/expand-icon';
import ModifyDropdown from '@/components/modify-dropdown';
import Panel from '@/components/panel';
import TableTooltip from '@/components/table-tooltip';
import useAllDraftOrderDrawer from '../../hooks/use-all-draft-order-drawer';
import Risks from '../Risks';
import ExpandedTabs from './expanded-tabs';
import OrderReleaseStatusLabel from './expanded-tabs/order-release-status-label';
import Header from './header';
import { RowSelectToolbar } from './row-select-toolbar';
import { useRowSelect } from './row-select-toolbar/use-row-select';
import useOrderQs from './use-order-qs';

const SORT_MAP = {
	ascend: 'asc',
	descend: 'desc',
};

interface OrderTableProps {
	tableProps?: TableProps<Order>;
}

const ErrorInfo = () => {
	return (
		<div className='relative flex-center hover-control'>
			<InfoCircleFilled className=' text-updated-red text-[20px]' />

			<div className=' absolute z-10 top-[60px] left-0 hover-content'>
				<TableTooltip
					icon={<InfoCircleOutlined className=' text-updated-red' />}
					title={t('Custom Hold')}
					content={
						<span>
							{t('Custom release has failed due to lack of required documents. Need help?')}
						</span>
					}
					tooltipColor='rgba(255, 46, 46, 0.8)'
					bgColor='#FFEEEE'
					className='w-[630px]'
				/>
			</div>
		</div>
	);
};

const DiversionInfo = () => {
	return (
		<div className='relative flex-center hover-control'>
			<DiversionIcon />

			<div className=' absolute z-10 top-[60px] left-0 hover-content'>
				<TableTooltip
					icon={<DiversionOutlinedIcon />}
					title={t('Containers Diversion')}
					content={<span> </span>}
					tooltipColor='rgba(255, 46, 46, 0.8)'
					bgColor='#FFEEEE'
					className='w-[630px]'
				/>
			</div>
		</div>
	);
};

export default observer(({ tableProps }: OrderTableProps) => {
	const { userContext } = useGlobalContext();

	const __enable_project_feature = !!userContext.getUserInfo()?.__enable_project_feature;

	const orderContext = useOrderContext();

	const { openDraftOrder, Drawer: AllDraftOrderDrawer } = useAllDraftOrderDrawer();

	const { parsedSearchParams, changeSearchParams, scrollToDefaultOrderId, cleanDefaultOrderId } =
		useOrderQs();

	const [paginationPage, setPaginationPage] = useState({
		total: 0,
		pageSize: parsedSearchParams.per_page,
		current: parsedSearchParams.page,
	});

	const { selectedRowKeys, onChange: onRowSelectChange, clearSelectedRowKeys } = useRowSelect();

	const showTotal = (total) => `${t('Total')} ${total}`;

	const fetchIndexRef = useRef(0);

	const [expandedRowKeys, setExpandedRowKeys] = useState([]);

	const orders = orderContext.orders;

	const hasError = (order: Order, container: Container) => {
		return (
			[
				'freight_released_at',
				'customs_released_at',
				'terminal_released_at',
				'ready_to_pickup_at',
			] as const
		).some((prop) => {
			return container[prop] === false;
		});
	};

	const onExpand = (expanded: boolean, record: Order) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.id]);
		} else {
			setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.id));
		}
	};

	const columns: TableProps<Order>['columns'] = [
		{
			key: 'mbl_number',
			title: t('MBL #'),
			render(_, record) {
				const risks = record.containers.map((c) => c.risks).flat();
				const uniqueRisks = [...new Set(risks)];
				console.log('risks', uniqueRisks);
				return (
					<div className='flex'>
						<div className='mr-[8px]'>
							<div
								className='underline cursor-pointer'
								onClick={() => onExpand(expandedRowKeys.includes(record.id) ? false : true, record)}
							>
								{record.mbl_number}{' '}
								<span>
									<Risks risks={uniqueRisks} />
								</span>
							</div>

							<div className='body-4-r'>
								{t('To')} {record?.warehouse?.city?.full_name ?? '-'}
							</div>
						</div>
						{/* {record.containers.some((container) => hasError(record, container)) && <ErrorInfo />} */}
						{record.has_division && <DiversionInfo />}
					</div>
				);
			},
		},
		{
			key: 'containers',
			title: t('Containers'),
			render(_, record) {
				return (
					<div>
						<div>
							{record.container_delivered_counts}/{record.container_count}
						</div>
						<div className='body-4-r'>{t('delivered')}</div>
					</div>
				);
			},
		},
		// {
		// 	key: 'earliest_eta',
		// 	dataIndex: ['earliest_eta'],
		// 	title: t('Earliest ETA'),
		// 	sorter: true,
		// },
		{
			key: 'final_port_eta',
			dataIndex: ['final_port_eta'],
			title: t('Final Port') + ' ETA',
			sorter: true,
		},
		{
			key: 'Intermodal_Region',
			dataIndex: ['final_port', 'name'],
			title: t('Final Port'),
		},
		{
			key: 'Release_Status',
			dataIndex: ['released_status'],
			title: (
				<Space>
					<div>{t('Release Status')}</div>
					<Tooltip title={t('RELEASE_STATUS_DESC')}>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			),
			render: (status) => {
				return <OrderReleaseStatusLabel status={status} />;
			},
		},
		{
			key: 'created_at',
			dataIndex: ['created_at'],
			title: t('Order Date'),
			sorter: true,
		},
		{
			key: 'Actions',
			title: t('Actions'),
			render: (_, record) => <ModifyDropdown isText={false} order={record} />,
		},
		Table.EXPAND_COLUMN,
	];

	const handleChange: TableProps<Order>['onChange'] = (
		pagination,
		filter,
		sorter: SorterResult<Order>,
	) => {
		console.log('page', pagination, 'filter', filter, 'sorter', sorter);
		const { current, pageSize } = pagination;

		const values = {
			...parsedSearchParams,
			page: current,
			per_page: pageSize,
		};

		const sort_value = SORT_MAP[sorter.order as keyof typeof SORT_MAP] || '';

		const sortValues = sort_value
			? {
					sort_value,
					sort_by: sorter.columnKey,
			  }
			: {};

		handleSearch({ ...values, ...sortValues });
	};

	const handleSearch = async (values) => {
		fetchIndexRef.current += 1;
		const fetchIndex = fetchIndexRef.current;
		orderContext.isFetchingOrders = true;

		const { created_at_start_date, created_at_end_date } = values;

		values = {
			...parsedSearchParams,
			...values,
			created_at_start_date: created_at_start_date
				? dayjs(created_at_start_date).format('YYYY-MM-DD')
				: undefined,
			created_at_end_date: created_at_end_date
				? dayjs(created_at_end_date).format('YYYY-MM-DD')
				: undefined,
		};

		try {
			const res = await orderContext.fetchOrders({
				params: {
					...values,
				},
			});

			if (fetchIndex === fetchIndexRef.current) {
				orderContext.orders = res.data.data;

				const isErrorPagination =
					res.data.meta.current_page !== 1 && (res.data.data ?? []).length === 0;

				const newPagination = isErrorPagination
					? {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: 1,
					  }
					: {
							total: res.data.meta.total,
							pageSize: res.data.meta.per_page,
							current: res.data.meta.current_page,
					  };

				setPaginationPage(newPagination);

				changeSearchParams({
					...values,
					per_page: newPagination.pageSize,
					page: newPagination.current,
				});
			}
		} finally {
			orderContext.isFetchingOrders = false;
		}
	};

	useEffect(() => {
		handleSearch(parsedSearchParams);
	}, []);

	const MyRow = ({ children, ...props }: any) => {
		useEffect(() => {
			if (props['data-row-key'] !== parsedSearchParams.defaultOrderId) return;
			scrollToDefaultOrderId();
			cleanDefaultOrderId();
		}, [scrollToDefaultOrderId, cleanDefaultOrderId]);

		return <tr children={children} {...props} />;
	};

	return (
		<div className='h-full relative'>
			<div className='flex align-end mb-[12px] '>
				<div className='h3-b'>{t('My Orders')}</div>
				<div
					className='ml-[16px] body-4-m-underline cursor-pointer self-end'
					onClick={openDraftOrder}
				>
					{t('See All Draft Orders')}
				</div>
			</div>
			<Panel classNames='h-[calc(100%_-_46px)]'>
				<Header onSearch={handleSearch} />
				<div className='h-[calc(100%_-_86px)]'>
					<Table
						{...(tableProps ?? {})}
						className='custom-table auto-h-full-table'
						loading={orderContext.isFetchingOrders}
						rowKey='id'
						columns={columns}
						dataSource={orders}
						components={{
							body: {
								row: MyRow,
							},
						}}
						expandable={{
							expandedRowRender: (record) => <ExpandedTabs order={record} />,
							expandedRowKeys,
							onExpand,
							// expandRowByClick: false,
							// expandedRowClassName: () => 'bg-[#DCECFD] border-l-[1px] border-solid border-[#000000]'
							expandIcon: ExpandIcon,
						}}
						pagination={{ position: ['bottomCenter'], ...paginationPage, showTotal }}
						onChange={handleChange}
						rowSelection={{
							selectedRowKeys,
							onChange: onRowSelectChange,
						}}
					/>
				</div>
			</Panel>
			{AllDraftOrderDrawer}
			{__enable_project_feature && (
				<RowSelectToolbar
					selectedRowKeys={selectedRowKeys}
					clearSelectedRowKeys={clearSelectedRowKeys}
				/>
			)}
		</div>
	);
});
