import { DraftOrder } from '@/interfaces/draft-order.interface';
import { useOrderContext } from '@/services/contexts';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import DraftOrderContainer from './draft-order-container';

interface DraftOrderListProps {
	className?: string;
	draftOrders?: DraftOrder[];
	max?: number;
}

export default observer(({ className, draftOrders, max }: DraftOrderListProps) => {
	const orderContext = useOrderContext();

	let _draftOrders = draftOrders ?? orderContext.draftOrders ?? [];

	if (max) {
		_draftOrders = _draftOrders.length > max ? _draftOrders.slice(0, max) : _draftOrders;
	}

	if (!_draftOrders || _draftOrders.length === 0) return null;

	return (
		<div className={clsx('flex', 'flex-wrap', className)}>
			{_draftOrders.map((draftOrder) => (
				<DraftOrderContainer draftOrder={draftOrder} className='mr-[20px] mb-[40px]' />
			))}
		</div>
	);
});
