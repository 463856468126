import { Button, ButtonProps } from 'antd';
import { t } from 'i18next';

export default function (props: ButtonProps) {
	return (
		<Button
			{...props}
			type='primary'
			ghost
			className='w-[212px] h-[54px] rounded-[10px] font-[700] text-[20px]'
		>
			{t('Back')}
		</Button>
	);
}
