import { Account, PrepayOrder } from '@/interfaces/prepay-order.interface';
import { addPrepayOrders, getAccount, getBanks, getPrepayOrders } from '@/services/apis';
import { makeAutoObservable, runInAction } from 'mobx';
import qs from 'qs';

class PrepayPrepayOrderContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _prepayOrders: PrepayOrder[] | undefined;

	// private _banks: any[] | undefined;

	private _account: Account | undefined;

	private _isFetchingPrepayOrders = false;

	get account() {
		return this._account;
	}

	set account(account: Account) {
		runInAction(() => {
			this._account = account;
		});
	}

	get prepayOrders() {
		return this._prepayOrders;
	}

	set prepayOrders(prepayOrders: typeof this._prepayOrders) {
		runInAction(() => {
			this._prepayOrders = prepayOrders;
		});
	}

	// get banks() {
	// 	return this._banks;
	// }

	// set banks(banks) {
	// 	runInAction(() => {
	// 		this._banks = banks;
	// 	});
	// }

	get isFetchingPrepayOrders() {
		return this._isFetchingPrepayOrders;
	}

	set isFetchingPrepayOrders(isFetchingPrepayOrders: typeof this._isFetchingPrepayOrders) {
		runInAction(() => {
			this._isFetchingPrepayOrders = isFetchingPrepayOrders;
		});
	}

	async fetchPrepayOrders(
		props: Parameters<typeof getPrepayOrders>[0],
		config?: {
			autoSet?: boolean;
			fromParams?: boolean;
		},
	) {
		if (config?.fromParams) {
			props = {
				params: qs.parse(window.location.search, { ignoreQueryPrefix: true }),
			} as unknown as Parameters<typeof getPrepayOrders>[0];
		}
		const res = await getPrepayOrders(props);
		if (config?.autoSet) {
			this.prepayOrders = res.data.data;
		}
		return res;
	}

	async fetchBanks() {
		const res = await getBanks();
		return res.data;
	}

	async addPrepayOrders(values) {
		const formData = new FormData();
		formData.append('documents[0]', values['document']);
		formData.append('amount', values['amount']);
		formData.append('bank', values['bank']);
		const result = await addPrepayOrders(formData);

		if (result) {
			this.refreshPrepayOrders();
		}
	}

	async fetchAccount(config?: { autoSet?: boolean; fromParams?: boolean }) {
		const res = await getAccount();
		if (config?.autoSet) {
			this._account = res.data.data;
		}
	}

	async refreshPrepayOrders() {
		return await this.fetchPrepayOrders(
			{
				params: {
					page: 1,
				},
			},
			{
				fromParams: true,
				autoSet: true,
			},
		);
	}
}

export const prepayOrder = new PrepayPrepayOrderContext();
