import { message } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { sendMessage } from '@/services/apis';
import { useOrderContext } from '@/services/contexts';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import MessageItem from './message-item';
import MessageSender from './message-sender';

interface MessagesListProps {
	order: Order;
}

export default observer(({ order }: MessagesListProps) => {
	const orderContext = useOrderContext();

	const messages = order.messages;

	const handleSendMessage = async (values: { body: string }) => {
		try {
			await sendMessage({ path: { orderId: order.id }, data: { body: values.body } });
			await orderContext.fetchOrders({ params: { page: 1 } }, { autoSet: true, fromParams: true });
		} catch (error) {
			message.error(t('send error'));
		}
	};

	return (
		<div className='h-[362px]'>
			<div className='pl-[20px] h-[258px] overflow-y-scroll bg-white'>
				{messages && messages.map((message) => <MessageItem message={message} />)}
			</div>
			<MessageSender onClick={handleSendMessage} />
		</div>
	);
});
