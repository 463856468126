import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { RequestedRatesResData } from '@/services/apis/get-requested-rates/interface';
import { useRequestedRateContext } from '@/services/contexts';
import '@/styles/auto-h-full-table.less';
import clsx from 'clsx';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import qs from 'qs';
import getCargoTypesQs from '../search-form/get-cargo-types-qs';
import useRequestedRatesParams from '../search-form/use-requested-rates-params';
import RejectionReason from './rejection-reason';

export default observer(function () {
	const requestedRateContext = useRequestedRateContext();
	const navigate = useNavigate();
	const { parsedSearchParams, changeSearchParams } = useRequestedRatesParams();

	const requestedRates = requestedRateContext.requestedRates;

	const columns: ColumnsType<any> = [
		{
			title: t('Date'),
			dataIndex: 'created_at',
			key: 'created_at',
		},
		{
			title: t('Destination'),
			dataIndex: ['to_city', 'full_name'],
		},
		{
			title: `${t('Port')}/${t('Ramp')}`,
			dataIndex: ['intermodal_region', 'name'],
		},
		{
			title: t('Cargo type'),
			dataIndex: 'cargo_types',
			render: (types) => (types ? '' : '/'),
		},
		{
			title: t('Memo'),
			dataIndex: 'memo',
		},
		{
			title: t('Action'),
			dataIndex: '',
			render: (_, record) => {
				return (
					<Button
						type='primary'
						onClick={() =>
							navigate(
								`/search-rate?${qs.stringify({
									intermodal_region_id: record.intermodal_region_id,
									to_city_id: record.to_city_id,
								})}`,
							)
						}
					>
						{t('View')}
					</Button>
				);
			},
		},
	];

	const handleChange: TableProps<RequestedRatesResData>['onChange'] = (pagination) => {
		const { current, pageSize } = pagination;

		const values = {
			...parsedSearchParams,
			page: current,
			per_page: pageSize,
		};

		changeSearchParams(values);

		requestedRateContext.fetchRequestedRatesAndAutoSet({
			params: {
				...values,
				cargo_types: getCargoTypesQs(values.cargo_types ?? []),
			},
		});
	};

	const MyRow = ({ children, ...props }: any) => {
		const record = requestedRates.find((r) => r.id == props['data-row-key']);

		// TODO 判断条件为 record 的state
		const isRejected = record?.state === 'rejected';
		const reason = record?.rejected_reason ?? '';

		return (
			<>
				<tr children={children} {...props} className={clsx(props.className, 'border-b-0')} />
				{isRejected && (
					<tr {...props} data-row-key='' className={clsx(props.className, 'border-b-0')}>
						<td colSpan={9}>
							<RejectionReason reason={reason} />
						</td>
					</tr>
				)}
			</>
		);
	};

	return (
		<Table
			columns={columns}
			dataSource={requestedRates}
			rowKey={'id'}
			loading={requestedRateContext.isFetching}
			className='auto-h-full-table custom-table'
			bordered={false}
			pagination={{ position: ['bottomCenter'], pageSize: 20 }}
			components={{
				body: {
					row: MyRow,
				},
			}}
			onChange={handleChange}
		/>
	);
});
