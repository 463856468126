import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { GetProjectContainersItem } from '@/services/apis';
import { DownOutlined, UpOutlined, WarningOutlined } from '@ant-design/icons';
import { useBoolean, useSafeState } from 'ahooks';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import { Gap } from '@/components/gap';
import { ExceptionTag } from '@/components/project-exceptions';
import { ProjectExceptionWarning } from '@/components/project-exceptions/exception-warning';
import { Table, TableProps, TCellRender } from '@/components/table';
import { ProgressLabel, ProgressMapKeysEnum } from '../../use-progress-map';

interface GroupTableProps {
	projectId: string | number;
	containers: GetProjectContainersItem[];
	group?: {
		label?: string;
		total?: number;
		returned?: number;
	};
}

export const GroupTable = ({ projectId, containers, group }: GroupTableProps) => {
	const { t } = useTranslation();

	const [open, { toggle }] = useBoolean(true);

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	const [selectedRecord, setSelectedRecord] = useSafeState<GetProjectContainersItem | null>();

	const ContainerNumberCellRender: TCellRender<GetProjectContainersItem> = ({
		style,
		className,
		value,
		record,
		onRowClick,
	}) => {
		const troubleStatus = record.trouble_status ?? [];

		return (
			<div style={{ ...(style ?? {}) }} className={`${className} flex items-center`}>
				<div className=' cursor-pointer' onClick={() => onRowClick(record)}>
					{value}
				</div>
				<Gap width='4px' />
				{record.has_demurrage_expcetion && <ExceptionTag reason='Demurrage' />}
				{troubleStatus.length > 0 && (
					<div className='flex items-center text-additional-warning'>
						<Gap width='12px' />
						<WarningOutlined />
						{troubleStatus.length}
					</div>
				)}
				{/* {troubleStatus.length > 0 && (
					<ProjectExceptionWarning exceptions={troubleStatus} />
				)} */}
			</div>
		);
	};

	const columns: TableProps<GetProjectContainersItem>['columns'] = [
		{
			title: '',
			dataIndex: '',
			type: 'extra',
			width: '52px',
		},
		{
			title: t('Container #'),
			dataIndex: 'number',
			CellRender: ContainerNumberCellRender,
		},
		{
			title: t('Progress'),
			dataIndex: 'progress_text',
			CellRender({ style, className, value, record, onRowClick }) {
				return (
					<div
						style={{ ...(style ?? {}) }}
						className={`${className}`}
						onClick={() => onRowClick(record)}
					>
						<ProgressLabel
							type={
								value
									.split(' ')
									.map((s) => s.toLowerCase())
									.join('_') as ProgressMapKeysEnum
							}
						/>
					</div>
				);
			},
		},
		{
			title: t('MBL'),
			dataIndex: ['order', 'mbl_number'],
		},
		{
			title: t('Pod'),
			dataIndex: ['port_of_discharge', 'name'],
		},
		{
			title: t('IR'),
			dataIndex: ['final_port', 'name'],
		},
		{
			title: t('Whs Name'),
			dataIndex: ['warehouse', 'name'],
		},
		{
			title: t('Whs City'),
			dataIndex: ['warehouse', 'city', 'full_name'],
		},
		{
			title: t('IR ETA'),
			dataIndex: ['final_port_eta'],
		},
		{
			title: t('LFD'),
			dataIndex: 'lfd',
		},
		{
			title: t('Est. Pickup'),
			dataIndex: 'schedule_terminal_pickup_at',
		},
	];

	return (
		<>
			<div>
				{group && (
					<div className='ml-[24px] w-fit flex items-center cursor-pointer' onClick={toggle}>
						{open ? (
							<DownOutlined width={24} style={{ fontWeight: 600 }} />
						) : (
							<UpOutlined width={24} />
						)}
						<Gap width='4px' />
						<div className='body-4-b text-black'>{group.label}</div>
						<Gap width='16px' />
						<div className='flex items-center'>
							<Progress
								type='circle'
								percent={(group.returned / group.total) * 100}
								showInfo={false}
								strokeColor='#08979C'
								trailColor='#87E8DE'
								size={24}
								strokeWidth={25}
								className='z-10'
							/>
							<div
								className=' box-border p-[0_8px_0_12px] h-[24px] flex items-center rounded-[0_12px_12px_0] bg-[#E6FFFB] foot-note-1-b text-[#08979C]'
								style={{ transform: 'translateX(-8px)' }}
							>
								{`${group.returned}/${group.total}`}
								<Gap width='4px' />
								{t('Returned')}
							</div>
						</div>
					</div>
				)}

				{open && (
					<Table
						columns={columns}
						dataSource={containers}
						className={`grow shrink overflow-scroll`}
						commonCellStyle={{ borderBottom: '1px solid #e7e7e7' }}
						commonCellClass={` box-border p-[0_10px] flex items-center box-border h-[44px]`}
						commonHeaderClass={`foot-note-1-m text-[#777777]`}
						commonBodyClass={`foot-note-1-r text-black whitespace-nowrap`}
						onRowClick={(record) => {
							setSelectedRecord(record);
							openContainerDetailModal();
						}}
					/>
				)}
			</div>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					mask={true}
					projectId={projectId}
					orderId={selectedRecord.order_id}
					containerId={selectedRecord.id}
					open={openingContainerDetailModal}
					onClose={() => {
						setSelectedRecord(null);
						closeContainerDetailModal();
					}}
				/>
			)}
		</>
	);
};
