import { useCallback, useEffect, useState } from 'react';
import { message, Pagination, PaginationProps } from 'antd';
import { DraftOrder } from '@/interfaces/draft-order.interface';
import { usePrepayOrderContext } from '@/services/contexts/prepay-order';
import { t } from 'i18next';
import PrepayOrderDrawer from './prepay-order-drawer';

const PrepayOrder = () => {
	const [open, setOpen] = useState(false);

	const [banks, setBanks] = useState([]);

	const [fetching, setFetching] = useState(false);

	const orderContext = usePrepayOrderContext();

	const openDrawer = useCallback(() => {
		setOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setOpen(false);
	}, []);

	const getbanks = async () => {
		setFetching(true);
		try {
			const res = await orderContext.fetchBanks();
			setBanks(res.data);
		} catch (error) {
			message.error(t('error'));
		} finally {
			setFetching(false);
		}
	};

	// const handlePaginationChange: PaginationProps['onChange'] = async (page, pageSize) => {
	// 	await getDraftOrders(page, pageSize);
	// 	setCurrentIndex(page);
	// };

	// 根据接口设置 total
	// const Footer = () => (
	// 	<div className='flex justify-center'>
	// 		<Pagination
	// 			defaultCurrent={currentIndex}
	// 			total={total}
	// 			pageSize={pageSize}
	// 			onChange={handlePaginationChange}
	// 		/>
	// 	</div>
	// );

	const Drawer = (
		<PrepayOrderDrawer
			width={894}
			open={open}
			fetching={fetching}
			banks={banks}
			onClose={closeModal}
		/>
	);

	useEffect(() => {
		if (open) {
			getbanks();
		}
	}, [open]);

	return {
		openDrawer,
		closeModal,
		Drawer,
	};
};

export default PrepayOrder;
