import { useEffect } from 'react';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd';
import clsx from 'clsx';
import { t } from 'i18next';

const Item = Form.Item;

interface EmailInputProps {
	form: FormInstance;
	onValid: (status: boolean) => void;
	inputClass?: string;
}

export default ({ form, inputClass, onValid }: EmailInputProps) => {
	const values = Form.useWatch([], form);

	useEffect(() => {
		form
			.validateFields(['email'], { validateOnly: true })
			.then(() => {
				onValid(true);
			})
			.catch((error) => {
				if (error.errorFields.length === 0) {
					onValid(true);
					return;
				}
				onValid(false);
			});
	}, [values]);

	return (
		<Item
			name='email'
			label={t('Email')}
			rules={[
				{ type: 'email', message: t('Invalid email address.') },
				{ required: true, message: t('Empty email address.') },
			]}
		>
			<Input
				className={clsx('w-full login-input', inputClass)}
				placeholder={t('Enter email address')}
			/>
		</Item>
	);
};
