import { useEffect, useRef, useState } from 'react';
import { Button, Empty, Form, message, Space, Tooltip } from 'antd';
import EmptyAddressImg from '@/assets/man-inside-mark-location.png?url';
import ContactIcon from '@/icons/contact-icon';
import EditIcon from '@/icons/edit-icon';
import HouseIcon from '@/icons/house-icon';
import WareHouseIcon from '@/icons/warehouse-icon';
import { PickupNumberAgent } from '@/interfaces/pickup-number-agent.interface';
import { getPickupNumberAgents, searchPickupNumberAgents } from '@/services/apis';
import { userContext } from '@/services/contexts/user';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useIntersection } from 'use-intersection';
import CenterSpin from '@/components/center-spin';
import Input from '@/components/input';
import { Title } from '../../../title';
import usePickupNUmberAgentModal from '../pickup-number-agent-modal';
import { PickupNumberAgentSelectProps } from './type';

const Item = Form.Item;

export const DEFAULT_PICKUP_NUMBER_AGENT = {
	id: 0,
	name: 'Myself',
};

export default observer(({ formItemKey, wrapperClass }: PickupNumberAgentSelectProps) => {
	const form = Form.useFormInstance();
	const userInfo = userContext.getUserInfo();

	const [isSearching, setIsSearching] = useState(false);

	const [isFetching, setIsFetching] = useState(false);

	const [searchText, setSearchText] = useState(null);

	const [pickupNumberAgents, setPickupNumberAgents] = useState<PickupNumberAgent[]>([]);

	const rootRef = useRef<HTMLDivElement>(null);

	const triggerRef = useRef<HTMLDivElement>(null);

	const intersecting = useIntersection(triggerRef, { root: rootRef, rootMargin: '-10px' });

	const [currentPage, setCurrentPage] = useState(0);

	const [isFinish, setIsFinish] = useState(false);

	const { PickupNumberAgentModal, openPickupNumberAgentModal } = usePickupNUmberAgentModal();

	const selectedPickupNumberAgent = Form.useWatch(formItemKey);

	const { run: handleSearch } = useDebounceFn(
		async (searchText: string) => {
			setIsSearching(true);

			const res = await searchPickupNumberAgents({ params: { name: searchText } });

			setPickupNumberAgents(res.data.data);

			setIsSearching(false);
		},
		{ wait: 500 },
	);

	const fetchpickupNumberAgent = async (page?: number) => {
		setIsFetching(true);
		page = page ?? currentPage + 1;
		try {
			const res = await getPickupNumberAgents({
				params: {
					per_page: 20,
					page,
				},
			});

			const pickupNumberAgents = res.data.data;
			if (pickupNumberAgents.length === 0) {
				setIsFinish(true);
			}
			setCurrentPage(page);
			setPickupNumberAgents((_pickupNumberAgents) => [
				..._pickupNumberAgents,
				...pickupNumberAgents,
			]);
		} catch (error: any) {
			message.error(error.message);
		} finally {
			setIsFetching(false);
		}
	};

	const addMyselfToPickupNumberAgent = () => {
		const _pickupNumberAgents = Object.assign([], pickupNumberAgents);
		if (_pickupNumberAgents.find((p) => p.id == 0)) {
			return;
		}

		_pickupNumberAgents.unshift(DEFAULT_PICKUP_NUMBER_AGENT);
		setPickupNumberAgents(_pickupNumberAgents);
	};

	useEffect(() => {
		addMyselfToPickupNumberAgent();
	}, [pickupNumberAgents]);

	useEffect(() => {
		// 初始化
		const preSelectedId = form.getFieldValue(formItemKey);
		!preSelectedId && form.setFieldValue(formItemKey, pickupNumberAgents?.[0]);
		fetchpickupNumberAgent();
	}, []);

	useEffect(() => {
		if (intersecting && !isFinish) {
			fetchpickupNumberAgent();
		}
	}, [intersecting]);

	return (
		<div className={clsx(wrapperClass)}>
			<Item
				label={
					<div className='flex justify-between items-center w-[100vw]'>
						{t('Pickup Number Agent')}
						<Button type='link' onClick={() => openPickupNumberAgentModal()}>
							+ {t('Add Pickup Number Agent')}
						</Button>
					</div>
				}
			>
				<div>
					<Input
						placeholder={t('Find a recent or saved pickup number agent')}
						value={searchText}
						onChange={(v) => {
							setSearchText(v.target.value);
							handleSearch(v.target.value);
						}}
					/>
				</div>
				<div
					ref={rootRef}
					className='flex-grow pt-[10px] pl-[10px] pr-[10px] bg-[#C8E3FF40] overflow-y-scroll'
				>
					{isSearching ? (
						<CenterSpin />
					) : pickupNumberAgents && !!pickupNumberAgents.length ? (
						<div className='max-h-[260px]'>
							{pickupNumberAgents.map((pickupNumberAgent) => {
								const isSelected = selectedPickupNumberAgent?.id === pickupNumberAgent.id;
								const handleSelect = () => {
									form.setFieldValue(formItemKey, pickupNumberAgent);
								};
								return (
									<div
										className={`box-border mb-[10px] pt-[9px] pl-[21px] pr-[11px] pb-[9px] rounded-[6px] bg-[#FFFFFF] ${
											isSelected ? 'border-[3px] border-solid border-[#007BFF]' : 'border-[#FFFFFF]'
										}`}
										onClick={handleSelect}
									>
										{/* header */}
										<div className='flex justify-between'>
											{/* left */}
											<div className='flex items-center'>
												{/* name */}
												<div className='font-[700] text-[18px]'>{pickupNumberAgent?.name}</div>
											</div>

											{/* right */}
											<div className='flex items-center'>
												{/* edit */}
												{userInfo?.id == pickupNumberAgent.user_id ? (
													<EditIcon
														className=' cursor-pointer'
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															openPickupNumberAgentModal(pickupNumberAgent);
														}}
													/>
												) : (
													<Tooltip
														title={`
												created by ${pickupNumberAgent?.user?.name}`}
													>
														{pickupNumberAgent?.user?.name}
													</Tooltip>
												)}
											</div>
										</div>
										{/* content */}
										{/* <div className='font-[400] text-[16px] text-[#545454]'>
											<div>
												{pickupNumberAgent.emails.join(', ')}
											</div>
										</div> */}
									</div>
								);
							})}
						</div>
					) : isFetching ? (
						<CenterSpin />
					) : (
						<div className='flex justify-center items-center h-full w-full'>
							<div className='flex flex-col items-center'>
								<Empty
									description={
										<div className='font-[400] text-[16px] text-[#003166]'>
											{t('No Pickup Number Agent found')}
										</div>
									}
								/>

								<div
									className='font-[700] text-[18px] text-[#003166] underline cursor-pointer'
									onClick={() => openPickupNumberAgentModal()}
								>
									+ {t('Add Pickup Number Agent')}
								</div>
							</div>
						</div>
					)}

					<div ref={triggerRef} className='h-1'>
						{/* {!isFinish && pickupNumberAgents.length <= 0 && <CenterSpin />} */}
					</div>
					<PickupNumberAgentModal
						onAfterSave={async (pickupNumberAgent: PickupNumberAgent) => {
							setIsFinish(false);
							setPickupNumberAgents([]);
							setSearchText(pickupNumberAgent.name);
							handleSearch(pickupNumberAgent.name);
						}}
					/>
				</div>
			</Item>
		</div>
	);
});
