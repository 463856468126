import { Button } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { FileAddOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import useUploadFileModal from '@/pages/order/hooks/use-upload-file-modal';

interface DocumentsHeaderProps {
	order: Order;
	fetchData: () => void;
}

export default ({ order, fetchData }: DocumentsHeaderProps) => {
	const { openModal, Modal } = useUploadFileModal();

	const files = order.files;

	return (
		<div className='mb-[12px] flex justify-between'>
			{/* left */}
			<div className='body-3-b'>
				<span>{t('Uploaded Files')}</span>
				<span className=' text-grey-02'>({files?.length ?? 0})</span>
			</div>
			{/* right */}
			<div>
				<Button type='primary' ghost onClick={() => openModal(order)}>
					{t('Upload')}
					<FileAddOutlined />
				</Button>
			</div>

			<Modal onOk={fetchData} />
		</div>
	);
};
