import { t } from 'i18next';

const cargoTypesMap = {
	is_dg: {
		key: 'is_dg',
		label: 'DG',
		value: 2,
	},
	is_overweight: {
		key: 'is_overweight',
		label: 'OW',
		value: 4,
	},
	is_reefer: {
		key: 'is_reefer',
		label: 'RF',
		value: 3,
	},
	is_soc: {
		key: 'is_soc',
		label: 'SOC',
		value: 5,
	},
} as const;

export type CargoTypesMap = typeof cargoTypesMap;

export type CargoTypesMapKeys = keyof typeof cargoTypesMap;

const getValueByKey = (key: CargoTypesMapKeys) => {
	return cargoTypesMap[key].value;
};

export { cargoTypesMap, getValueByKey };
