import { useCallback, useState } from 'react';
import { Order } from '@/interfaces/order.interface';

export const useRowSelect = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<Order['id'][]>([]);

	const onChange = useCallback(
		(selectedRowKeys: Order['id'][]) => {
			console.log(`[useRowSelect] onChange ${selectedRowKeys}`);
			setSelectedRowKeys([...selectedRowKeys]);
		},
		[setSelectedRowKeys],
	);

	const clearSelectedRowKeys = useCallback(() => {
		setSelectedRowKeys([]);
	}, [setSelectedRowKeys]);

	return {
		selectedRowKeys,
		onChange,
		clearSelectedRowKeys,
	};
};
