import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, MenuProps } from 'antd';
import historySvg from '@/assets/history.svg';
import locationSvg from '@/assets/location.svg';
import ordersSvg from '@/assets/orders.svg';
import searchSvg from '@/assets/search.svg';
import settingSvg from '@/assets/setting.svg';
import walletSvg from '@/assets/wallet.svg';
import Home24pxIcon from '@/icons/home-24px-icon';
import { userContext } from '@/services/contexts/user';
import { NON_PREPAYABLE_ACCOUNT, TYPE_THIRD_PARTY } from '@/services/contexts/user/constant';
import { observer } from 'mobx-react-lite';
import { Logo } from './logo';
import { MenuGroup } from './menu-group';
import { MenuGroupProps } from './menu-group/interface';
import { UserInfo } from './user-info';

const { Sider: AntdSider } = Layout;

export const Sider = observer(() => {
	const { i18n, t } = useTranslation();

	const handleChangeLanguage: MenuProps['onClick'] = ({ key }) => {
		i18n.changeLanguage(key);
		window.location.href = '/';
	};

	const __enable_project_feature = !!userContext.getUserInfo()?.__enable_project_feature;

	const menu1 = [
		...[
			{
				label: t('Search Rate'),
				key: 'search-rate',
				icon: <img src={searchSvg} />,
				hide: [TYPE_THIRD_PARTY],
			},
			{
				label: t('Rate Request'),
				key: 'rate-request',
				icon: <img src={historySvg} />,
				hide: [TYPE_THIRD_PARTY],
			},
			{
				label: t('Orders'),
				key: 'orders',
				icon: <img src={ordersSvg} />,
				hide: [],
			},
			{
				label: t('Wallet'),
				key: 'prepay-orders',
				icon: <img src={walletSvg} />,
				hide: [TYPE_THIRD_PARTY, NON_PREPAYABLE_ACCOUNT],
			},
		],
		...(__enable_project_feature
			? [
					{
						label: t('Dashboard'),
						key: 'projects',
						icon: <Home24pxIcon />,
						hide: [],
					},
			  ]
			: []),
	];

	const menu2: MenuGroupProps['items'] = [
		// {
		// 	label: 'Profile',
		// 	key: 'profile',
		// 	icon: <img src={profileSvg} />,
		// },
		{
			label: t('Address'),
			key: 'addresses',
			icon: <img src={locationSvg} />,
		},
		{
			label: t('Language'),
			key: 'setting',
			icon: <img src={settingSvg} />,
			children: [
				{
					key: 'en-US',
					label: 'English',
					onClick: handleChangeLanguage,
				},
				{
					key: 'zh-CN',
					label: '中文',
					onClick: handleChangeLanguage,
				},
			],
		},
	];

	const _menu1 = useMemo(() => {
		return menu1.filter((m) => {
			return (
				!m.hide.includes(userContext.type) &&
				(userContext.prepayable || !m.hide.includes(NON_PREPAYABLE_ACCOUNT))
			);
		});
	}, [userContext.type, userContext.prepayable]);

	return (
		<AntdSider width={240} className=' bg-[#003166]'>
			<div className='flex flex-col justify-between h-full'>
				<div>
					{/* logo */}
					<div className='mb-[48px]'>
						<Logo />
					</div>
					{/* menu group */}
					<MenuGroup name={t('M A I N M E N U')} items={_menu1} />
					<MenuGroup name={t('P R E F E R E N C E S')} items={menu2} />
				</div>

				<div className='mb-[36px]'>
					{/* help */}
					{/* <Help /> */}
					{/* user info */}
					<UserInfo />
				</div>
			</div>
		</AntdSider>
	);
});
