import { Order } from '@/interfaces/order.interface';
import { observer } from 'mobx-react-lite';
import Avatar from '@/components/avatar';
import { ArrElement } from '@/utils/array-element.type';

interface MessageItemProps {
	message: ArrElement<Order['messages']>;
}

export default observer(({ message }: MessageItemProps) => {
	const { id, body, messager_type, created_at, updated_at, messager } = message;
	const { name } = messager;

	return (
		<div className='p-[10px_64px_12px_0] flex items-center border-t-0 border-r-0 border-l-0 border-b-[1px]  border-solid border-grey-03'>
			<Avatar className='mr-[16px] w-[48px] h-[48px]' name={name} />

			<div>
				{/* name & create */}
				<div>
					<span className='mr-[8px] body-4-b'>{name}</span>
					<span className='foot-note-1-m text-grey-02'>{created_at}</span>
				</div>

				<div className='body-4-r'>{body}</div>
			</div>
		</div>
	);
});
