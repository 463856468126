import { SelectProps } from 'antd';
import { useGlobalContext } from '@/services/contexts';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';

export default observer(({ className, ...props }: SelectProps) => {
	const { cacheContext } = useGlobalContext();

	const options = cacheContext.intermodalRegions.map((i) => ({
		label: i.name,
		value: i.id,
	}));

	return (
		<Select
			{...props}
			className={clsx(className)}
			options={options}
			showSearch
			allowClear
			filterOption={(input, option) =>
				(String(option?.label) ?? '').toLowerCase().includes(input.toLowerCase())
			}
		/>
	);
});
