import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import CenterSpin from './components/center-spin';
import useNavigateToIndex from './hooks/use-navigate-to-index';
import { billingCodeNameTranslateKey } from './utils/billing-code-translate';
import { unauthedNavigate } from './utils/unauthed-navigate';
import './App.less';
import { LANGUAGE_EN_US, LANGUAGE_ZH_CN, updateLanguagePack } from './i18n';
import { BillingCode } from './interfaces/billing-code.interface';
import { Sider } from './layout/sider';
import axiosInstance from './services/apis/axios';
import { useBillingCodeContext, useGlobalContext } from './services/contexts';
import { book } from './services/contexts/book/book.context';
import accessTokenManager from './services/contexts/user/access-token-manager';

const { Content } = Layout;

const App = observer(() => {
	const { navigateToIndex } = useNavigateToIndex();

	const { i18n } = useTranslation();

	const location = useLocation();

	const { userContext, cacheContext, screenContext } = useGlobalContext();

	const billingCodeContext = useBillingCodeContext();

	const [isFetching, setIsFetching] = useState(true);

	const currentPath = location.pathname;

	if (currentPath === '/') {
		navigateToIndex();
	}

	const updateLangauageWithBillingCodes = async () => {
		billingCodeContext.fetchBillingCodes().then((res: BillingCode[]) => {
			res.map((billingCode) => {
				updateLanguagePack(
					LANGUAGE_EN_US,
					billingCodeNameTranslateKey(billingCode.name),
					billingCode.name,
				);
				updateLanguagePack(
					LANGUAGE_EN_US,
					billingCodeNameTranslateKey(billingCode.code),
					billingCode.name,
				);
				updateLanguagePack(
					LANGUAGE_ZH_CN,
					billingCodeNameTranslateKey(billingCode.name),
					billingCode.cn_name,
				);
				updateLanguagePack(
					LANGUAGE_ZH_CN,
					billingCodeNameTranslateKey(billingCode.code),
					billingCode.cn_name,
				);
			});
		});
	};

	const init = async () => {
		// 判断 access token 是否存在
		if (!accessTokenManager.isAccessTokenValid()) {
			throw new Error('unauthed');
		}

		// 判断 access token 是否合法
		await userContext.fetchUserInfoByAccessToken();
		await updateLangauageWithBillingCodes();
	};

	const initWithToken = async (token) => {
		const resp = await axiosInstance.get('/retrieveToken', {
			params: { __auth_token__: token },
		});
		accessTokenManager.setAccessToken(resp.data.access_token);
	};

	const initEntry = async () => {
		try {
			setIsFetching(true);

			const urlParams = new URLSearchParams(window.location.search);
			if (urlParams.get('__auth_token__')) {
				await initWithToken(urlParams.get('__auth_token__'));
			}

			await init();
			await cacheContext.init();
			setIsFetching(false);
		} catch (error) {
			unauthedNavigate();
		}
	};

	useEffect(() => {
		initEntry();
	}, []);

	// 离开 book 页面时清除原数据
	useEffect(() => {
		if (location.pathname !== '/search-rate/book') {
			book.clear();
		}
	}, [location]);

	if (isFetching || cacheContext.fetching) return <CenterSpin size='large' />;

	return (
		<Layout className={clsx(screenContext.isScreenShot ? 'h-max' : 'h-screen')}>
			<Sider />
			<Content id='main'>
				<Outlet />
			</Content>
		</Layout>
	);
});

export default App;
