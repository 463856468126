import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// import timezone from 'timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const today = (): dayjs.Dayjs => {
	return dayjs().tz('America/Los_Angeles');
};
