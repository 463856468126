import { Tooltip } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

const Risks = ({ risks }) => {
	return (
		<>
			{risks?.length > 0 && (
				<Tooltip title={risks.join(', ')}>
					<WarningTwoTone twoToneColor='#FFAD01' />
				</Tooltip>
			)}
		</>
	);
};

export default Risks;
