import { getProjectMyFocusTotal } from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetProjectMyFocusTotal = (options?: Record<any, any>) => {
	const res = useRequest(getProjectMyFocusTotal, {
		manual: true,
		onError: (error) => {
			message.error(`${error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res?.data?.data ?? [],
	};
};
