import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { AllPanel } from './all-panel';
import { ExceptionPanel } from './exception-panel';
import { ProjectsHeader } from './header';
import { KindsPanel } from './kinds-panel';
import { PastPanel } from './past-panel';
import { ProgressPanel } from './progress-panel';

export default observer(() => {
	return (
		<div className='h-full w-full flex flex-col overflow-scroll'>
			{/* header */}
			<div className='grow-0 shrink-0'>
				<ProjectsHeader />
			</div>

			{/* main */}
			<div className='grow shrink box-border p-[12px] flex bg-[#f1f5f8] overflow-hidden'>
				{/* left */}
				<div className='grow shrink h-full flex flex-col overflow-hidden'>
					<div className='p-[24px] flex-grow-0 rounded-[16px] bg-white'>
						<PastPanel />
					</div>
					<Gap height='12px' />
					<div className='grow shrink box-border p-[24px] rounded-[16px] bg-white overflow-hidden'>
						<AllPanel />
					</div>
				</div>

				<Gap width='12px' />

				{/* right */}
				<div className='grow-0 shrink-0 box-border p-[24px] h-full w-[384px] flex flex-col rounded-[16px] bg-white overflow-hidden'>
					<div className='grow-0 shrink-0'>
						{/* <KindsPanel /> */}
						<ProgressPanel />
					</div>
					<Gap height='24px' />
					<div className='grow shrink overflow-hidden'>
						<ExceptionPanel />
					</div>
				</div>
			</div>
		</div>
	);
});
