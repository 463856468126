import { PossibleCharge } from '@/interfaces/possible-charges.interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

interface RateTextPros {
	possibleCharge: PossibleCharge;
}

export default observer(({ possibleCharge }: RateTextPros) => {
	// @ts-ignore
	const text = possibleCharge.rate_text;

	if (String(text).indexOf(' ') !== -1) {
		return (
			<>
				<div>${text.substring(0, text.indexOf(' '))}</div>
				<div>{text.substring(text.indexOf(' ') + 1)}</div>
			</>
		);
	}
	if (Number(possibleCharge.rate) === 0) {
		return <strong>{t('FREE')}</strong>;
	}

	return <>${text}</>;
});
