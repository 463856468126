import drayEasy from '@/assets/dray-easy.svg';
import logo from '@/assets/logo.svg';
import clsx from 'clsx';

interface LogoProps {
	wrapperClass?: string;
	logoWrapperClass?: string;
}

export const Logo = ({ wrapperClass, logoWrapperClass }: LogoProps) => {
	return (
		<div
			className={clsx(
				'flex justify-between items-center mt-[36.5px] w-full h-fit pl-[36px] pr-[26px]',
				wrapperClass,
			)}
		>
			<div
				className={clsx(
					' w-[40px] h-[40px] pt-[9.17px] pl-[7.5px] pb-[5px] pr-[5px] bg-[#007BFF] rounded-[8px]',
					logoWrapperClass,
				)}
			>
				<img src={logo} />
			</div>
			<img className='  w-[126px] h-[66px]' src={drayEasy} />
		</div>
	);
};
