import clsx from 'clsx';
import { t } from 'i18next';

interface OrderReleaseStatusLabel {
	status: string;
}

const styleMap: Record<string, string> = {
	['All Released']: 'text-updated-green bg-[#DFF0DF]',
	['Not Released']: 'text-grey-01 bg-grey-05',
	['On Hold']: 'text-updated-red bg-[#FFE0E0]',
};

export default ({ status }: OrderReleaseStatusLabel) => {
	const className = styleMap[status];

	return (
		<div
			className={clsx(
				'p-[8px] w-[100px] h-[32px] flex-center rounded-[50px] foot-note-1-m',
				className,
			)}
		>
			{t(status)}
		</div>
	);
};
