import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Col,
	Divider,
	Drawer,
	DrawerProps,
	Form,
	FormInstance,
	InputNumber,
	message,
	Row,
	Space,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { getExchangeRate } from '@/services/apis';
import { usePrepayOrderContext } from '@/services/contexts/prepay-order';
import { InboxOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { find } from 'lodash';
import { observer } from 'mobx-react-lite';
import Input from '@/components/input';
import Select from '@/components/select';

interface PrepayOrderDrawerProps extends DrawerProps {
	banks: any[];
	fetching: boolean;
}

const PrepayOrderDrawer = observer(({ banks, ...props }: PrepayOrderDrawerProps) => {
	const refForm = useRef<FormInstance>(null);

	const [submitting, setSubmitting] = useState(false);
	const [bank, setBank] = useState<number>();
	const [convertResult, setConvertResult] = useState<{
		rate: number;
		cost: number;
		amount: number;
	}>();
	const [fileList, setFileList] = useState<any[]>([]);

	const [amount, setAmount] = useState<number>();

	const orderContext = usePrepayOrderContext();

	const handleUpload = (info: any) => {
		let fileList: any[] = [...info.fileList];

		fileList = fileList.slice(-1);
		setFileList(fileList);
		refForm.current?.setFieldValue('document', info.file);
	};

	const onFinish = useCallback(async (values: any) => {
		setSubmitting(true);
		try {
			await orderContext.addPrepayOrders(values);
			message.success('Success');
			refForm.current.resetFields();
			setFileList([]);
			props.onClose && props.onClose(null);
		} catch (e: any) {
			message.error('Failed');
		} finally {
			setSubmitting(false);
		}
	}, []);

	const bankInfo = useMemo(() => {
		const info = find(banks, { id: bank });
		return info || null;
	}, [bank]);

	useEffect(() => {
		if (bankInfo && bankInfo.currency !== 'USD') {
			if (!amount || amount <= 0) {
				setConvertResult(undefined);
				return;
			}
			(async () => {
				try {
					const exchangeRate = await getExchangeRate(bankInfo.currency);
					const cost = parseFloat(`${bankInfo.custom_properties?.exchange_rate_cost || 0}`);
					const result = amount / (exchangeRate.data + cost);
					setConvertResult({
						rate: exchangeRate.data,
						cost,
						amount: result,
					});
				} catch (e: any) {
					message.error(e.toString() || 'Failed to obtain real-time exchange rate.');
				}
			})();
		} else {
			setConvertResult(undefined);
			return;
		}
	}, [bankInfo, amount]);

	return (
		<Drawer {...props} title={t('Add Funds')}>
			<Form
				ref={refForm}
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 20 }}
				onFinish={onFinish}
				autoComplete='off'
			>
				<Form.Item name='bank' label={t('Payment method')} rules={[{ required: true }]}>
					<Select
						placeholder={t('Select Bank Account')}
						options={banks.map((v) => ({ label: v.name, value: v.id }))}
						onChange={(v) => setBank(v)}
					/>
				</Form.Item>
				<Form.Item name='amount' label={t('Amount')} rules={[{ required: true }]}>
					<InputNumber
						min={0}
						style={{ width: 150 }}
						prefix={bankInfo?.currency}
						onChange={(value: any) => setAmount(value)}
					/>
				</Form.Item>
				{bankInfo && bankInfo.currency !== 'USD' && (
					<Row>
						<Col span={10}>
							<Form.Item label={t('Currency')} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
								<Input
									readOnly
									style={{ width: 150 }}
									prefix='USD'
									value={
										convertResult ? (((convertResult.amount * 100) << 0) / 100).toFixed(2) : ''
									}
								/>
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
								label={t('Exchange Rate')}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
							>
								<span className='ant-form-text'>
									{' '}
									{convertResult ? convertResult.rate.toFixed(4) : ''}
								</span>
							</Form.Item>
						</Col>
						<Col span={7}>
							<Form.Item
								label={t('Exchange Loss')}
								labelCol={{ span: 12 }}
								wrapperCol={{ span: 12 }}
							>
								<span className='ant-form-text'>
									{' '}
									{convertResult ? convertResult.cost.toFixed(2) : ''}
								</span>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Form.Item
					name='document'
					label={t('Doc of receipt')}
					rules={[{ required: true }]}
					valuePropName='document'
					getValueFromEvent={(e) => {
						if (Array.isArray(e)) {
							return e;
						}
						return e.fileList;
					}}
				>
					<Dragger
						beforeUpload={() => false}
						name='image'
						listType='picture'
						fileList={fileList}
						onChange={handleUpload}
						maxCount={1}
					>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p className='ant-upload-text'>{t('Click or drag file to this area to upload')}</p>
						<p className='ant-upload-hint'>
							{t(
								'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
							)}
						</p>
					</Dragger>
				</Form.Item>
				<Divider />

				<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<Space align='end'>
						<Button type='default' onClick={close}>
							{t('Cancel')}
						</Button>
						<Button type='primary' loading={submitting} onClick={() => refForm.current?.submit()}>
							{t('Submit')}
						</Button>
					</Space>
				</div>
				{/* </Col>
				</Row> */}
			</Form>
		</Drawer>
	);
});

export default PrepayOrderDrawer;
