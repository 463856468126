import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { Order } from '@/interfaces/order.interface';
import { getContainer, getOrder } from '@/services/apis';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Risks from '../order/components/Risks';
import CenterSpin from '@/components/center-spin';
import OrderDetailContainer from './order-detail-container';
import ContainerHeader from './order-detail-container/container-header';

const Tag = ({ label, value }: { label: string; value: string }) => (
	<div className='p-[2px_12px] h-[20px] w-fit flex-center rounded-[10px] border-[1px] border-solid border-grey-02 font-note-1-r'>
		<span className='mr-[4px] text-grey-01'>{label}</span>
		<span className='text-black'>{value}</span>
	</div>
);

export default observer(() => {
	const { orderId, containerId } = useParams();

	const [fetching, setFetching] = useState(false);

	const [order, setOrder] = useState<Order>();
	const [container, setContainer] = useState<ContainerDetail>();

	const fetchData = async () => {
		return await Promise.allSettled([
			getOrder({ path: { orderId: Number(orderId) } }),
			getContainer({ path: { containerId: Number(containerId) } }),
		]).then(([orderRes, containerRes]) => {
			// @ts-ignore
			setOrder(orderRes.value.data.data);
			// @ts-ignore
			setContainer(containerRes.value.data.data);
		});
	};

	useEffect(() => {
		setFetching(true);
		fetchData()
			.catch(() => {})
			.finally(() => {
				setFetching(false);
			});
	}, [orderId, containerId]);

	if (fetching) return <CenterSpin size='large' />;

	if (!order || !container) return null;

	const {
		number,
		seal_number,
		port_of_loading_etd,
		port_of_discharge_eta,
		actual_terminal_pickuped_at,
		actual_delivered_at,
		actual_empty_returned_at,
		freight_released_at,
		customs_released_at,
		terminal_released_at,
		ready_to_pickup_at,
		shipping_events,
		risks,
	} = container;

	//  TODO label
	return (
		<div className='p-[20px_8px_0_8px] h-full flex flex-col'>
			{/* header */}
			<div className='mb-[16px] p-[0_20px] flex justify-between'>
				{/* left */}
				<div>
					<ContainerHeader
						number={number}
						status_text={container.status_text}
						risks={container.risks}
						order_uid={order.uid}
					/>
				</div>
				{/* right */}
				<div>{/* <ModifyDropdown isText order={order}  />  */}</div>
			</div>

			{/* box */}
			<div className='p-[8px_20px_80px_20px] h-1 flex-grow rounded-[8px] bg-white shadow-[0px_4px_10px_0px_#0000000D]'>
				<OrderDetailContainer order={order} container={container} fetchData={fetchData} />
			</div>
		</div>
	);
});
