import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Col, Form, message, Modal, Row, Space } from 'antd';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { createWarehouse } from '@/services/apis/create-warehouse';
import { isWarehouseDuplicate } from '@/services/apis/is-warehouse-duplicate';
import { updateWarehouse } from '@/services/apis/update-warehouse';
import { t } from 'i18next';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';
import { CitySelect } from '../../../city-select';
import { Input, InputTextArea } from '../../../input';
import AddressAutoFill from './AddressAutoFill';
import ContactsTable from './contacts-table';
import ResidentialRadio from './residential-radio';
import { AddressModalProps } from './type';
import UseDuplicateRadio from './use-duplicate-radio';

const Item = Form.Item;

export default () => {
	const [open, setOpen] = useState(false);

	const [warehouse, setWarehouse] = useState<Warehouse>();

	const openAddressModal = useCallback((warehouse?: Warehouse) => {
		setOpen(true);
		setWarehouse(warehouse);
	}, []);

	const cityRef = useRef(null);

	const closeAddressModal = useCallback(() => {
		setOpen(false);
		setWarehouse(undefined);
	}, []);

	const AddressModal = observer(({ onAfterSave, ...props }: AddressModalProps) => {
		const [confirmLoading, setConfirmLoading] = useState(false);

		const [form] = Form.useForm<Warehouse>();

		const [editWarehouse, setEditWarehouse] = useState<Warehouse | null>(warehouse || null);

		// const [duplicateWarehouse, setDuplicateWarehouse] = useState<Warehouse | null>(null);

		const isCreating = !editWarehouse;

		const handleFinish = async (values: any) => {
			values = { ...values, city_id: values?.city_id?.value };

			setConfirmLoading(true);

			const action = isCreating ? 'Create' : 'Update';

			let res;
			try {
				if (isCreating) {
					res = await createWarehouse({ data: values });
				} else {
					res = await updateWarehouse({
						path: {
							warehouseId: editWarehouse.id,
						},
						data: values,
					});
				}

				message.success(t(`${action} address success`));
				onAfterSave && onAfterSave(res?.data?.data);
				closeAddressModal();
			} catch (error) {
				message.error(t(`${action} address error: ${get(error, 'response.data.message')}`));
			} finally {
				setConfirmLoading(false);
			}
		};

		const checkIsDuplicate = async (address: string, zipcode: string) => {
			if (!isCreating || !address || !zipcode) {
				return;
			}

			try {
				const res = await isWarehouseDuplicate({
					params: {
						address,
						zipcode,
					},
				});

				if (res?.data?.data) {
					Modal.confirm({
						title: t('Duplicate Warehouse'),
						content: t(
							'This warehouse already exists in the system. Do you want to select an existing warehouse?',
						),
						okText: t('Yes'),
						cancelText: t('No'),
						onOk: async () => {
							setEditWarehouse(res?.data?.data);
						},
					});
				}
			} catch (error) {
				message.error(t(`${get(error, 'response.data.message')}`));
			}
		};

		// const handleIsUseWithDuplicate = (isUse: boolean) => {
		// 	if (isUse) {
		// 		setEditWarehouse(duplicateWarehouse);
		// 		setDuplicateWarehouse(undefined);
		// 	}
		// };

		useEffect(() => {
			if (!editWarehouse) return;
			form.setFieldsValue({ ...editWarehouse });
		}, [editWarehouse]);

		return (
			<Modal
				{...props}
				open={open}
				title={isCreating ? t('Add a New Delivery Address') : t('Edit Delivery Address')}
				width={'80%'}
				footer={null}
				onCancel={closeAddressModal}
				centered
			>
				<Form
					form={form}
					onFinish={handleFinish}
					layout='vertical'
					initialValues={
						editWarehouse
							? {
									...editWarehouse,
									city_id: {
										label: editWarehouse?.city?.full_name,
										value: editWarehouse?.city?.id,
									},
							  }
							: {}
					}
				>
					<Row gutter={80}>
						{/* left */}
						<Col span={12}>
							<Row gutter={15}>
								<Col span={12}>
									<Item name='name' label={t('Company Name')} rules={[{ required: true }]}>
										<Input />
									</Item>
								</Col>
								<Col span={12}>
									<Item name='code' label={t('Company Code')}>
										<Input />
									</Item>
								</Col>
							</Row>
							<Form.Item noStyle shouldUpdate>
								{({ getFieldValue, setFieldsValue }) => (
									<Item name='address' label={t('Address Line')} rules={[{ required: true }]}>
										<AddressAutoFill
											value={getFieldValue('address')}
											onSelect={(_data) => {
												checkIsDuplicate(_data.address, _data.zipcode);
												setFieldsValue({
													city_id: { label: _data?.city?.full_name, value: _data?.city?.id },
													address: _data.address,
													zipcode: _data.zipcode,

													// delivery_order_address: toAddress({
													// 	...form.getFieldsValue(),
													// 	..._data,
													// }),
												});
												cityRef && cityRef?.current?.updateCities(_data.city ? [_data.city] : null);
												// if (_data.classification == 0) {
												// 	setResidentialWarning(true);
												// }
											}}
										/>
									</Item>
								)}
							</Form.Item>

							<Row gutter={15}>
								<Col span={12}>
									<Item name='city_id' label={t('City')} rules={[{ required: true }]}>
										<CitySelect ref={cityRef} />
									</Item>
								</Col>
								<Col span={12}>
									<Form.Item noStyle shouldUpdate>
										{({ getFieldValue, setFieldsValue }) => (
											<Item name='zipcode' label={t('Zip Code')} rules={[{ required: true }]}>
												<Input
													onBlur={(e) => {
														checkIsDuplicate(getFieldValue('address'), e.target.value);
													}}
												/>
											</Item>
										)}
									</Form.Item>
								</Col>
							</Row>

							<Item name='is_residential' label={t('Is this a limited access address?')}>
								<ResidentialRadio formItemKey='is_residential' />
							</Item>
							{/* {duplicateWarehouse && isCreating && (
								<Item
									label={t(
										'This warehouse already exists in the system. Do you want to select an existing warehouse?',
									)}
								>
									<UseDuplicateRadio onChange={handleIsUseWithDuplicate} />
								</Item>
							)} */}
						</Col>

						{/* right */}
						<Col span={12}>
							<Item name='customer_memo' label={t('Delivery Notes')}>
								<InputTextArea rows={10} />
							</Item>

							<Item
								name='appointment_by'
								label={t('Who Make Appointment?')}
								rules={[{ required: true }]}
							>
								<Select
									options={[
										{
											label: t('Unknown'),
											value: 0,
										},
										{
											label: t('No Apt Need'),
											value: 1,
										},
										{
											label: t("I'll do it"),
											value: 2,
										},
										{
											label: t('DrayEasy'),
											value: 3,
										},
									]}
								/>
							</Item>
						</Col>
					</Row>

					<Item name='contacts' label={t('Contacts')}>
						<ContactsTable formItemKey='contacts' />
					</Item>

					<div className='flex justify-end'>
						{/* <Button type='primary' ghost onClick={closeAddressModal}>
							{t('Cancel Default')}
						</Button> */}
						<Button type='primary' htmlType='submit' loading={confirmLoading}>
							{t('Save')}
						</Button>
					</div>
				</Form>
			</Modal>
		);
	});

	return {
		AddressModal,
		openAddressModal,
		closeAddressModal,
	};
};
