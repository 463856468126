import { useRef, useState } from 'react';
import { Badge, Button, Form, FormProps } from 'antd';
import NoticeIcon from '@/icons/notice-icon';
import SearchIcon from '@/icons/search-icon';
import { CargoSelectValue } from '@/interfaces/cargo-select-value.enum';
import { WeightUnit } from '@/interfaces/weight-unit.enum';
import { useSearchRateContext } from '@/services/contexts';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select';
import { CargoSelect } from './components/cargo-select';
import { CntrSizeGroup } from './components/cntr-size-group';
import { IntermodalRegionSelect } from './components/intermodal-region-select';
import { ResidentialAddressCheckbox } from './components/residential-address-checkbox';
import { ToCitySelect } from './components/to-city-select';
import calcIsOverWeight from '@/utils/calc-is-over-weight';
import { OnSearchFormFinish } from '../../interface';
import './index.less';

interface SearchRateFormProps {
	onFinish: OnSearchFormFinish;
}

export const SearchRateForm = observer(({ onFinish }: SearchRateFormProps) => {
	const [form] = Form.useForm();

	const [selectedCity, setSelectedCity] = useState(null);

	const [isSearching, setIsSearching] = useState(false);

	const searchRateContext = useSearchRateContext();

	const intermodalRegionSelectRef = useRef<BaseSelectRef | null>(null);

	const remainingRateSearchLimit = searchRateContext.remainingRateSearchLimit;
	const rateSearchLimit = searchRateContext.rateSearchLimit;

	const handleValuesChange: FormProps['onValuesChange'] = (changedValue, values) => {
		watchContainerChanges(changedValue, values);
	};

	// 自动标记 OW
	const watchContainerChanges: FormProps['onValuesChange'] = (changedValue, values) => {
		const weight = changedValue.weight;
		if (!weight || weight === '') return;
		const cargo_types = values.cargo_types ?? [];
		const isReefer = cargo_types.includes(CargoSelectValue.RF);
		const size = Number(values.cntr_size);
		const unit = values.weight_unit;

		const preIsOverweight = cargo_types.includes(CargoSelectValue.OW);
		const isOverweight = calcIsOverWeight(isReefer, size, Number(weight), unit);

		if (isOverweight && !preIsOverweight) {
			form.setFieldValue('cargo_types', [...cargo_types, CargoSelectValue.OW]);
		} else if (!isOverweight && preIsOverweight) {
			form.setFieldValue(
				'cargo_types',
				cargo_types.filter((v) => v !== CargoSelectValue.OW),
			);
		}
	};

	const handleFinish = async (values: any) => {
		setIsSearching(true);
		try {
			await onFinish(values, {
				selectedCity,
			});
		} finally {
			setIsSearching(false);
		}
	};

	return (
		<div className=' z-10 w-full absolute' style={{ top: 'calc(50vh - 112px)' }}>
			<Form
				onFinish={handleFinish}
				form={form}
				initialValues={{ cntr_size: 40, unit: WeightUnit.LB }}
				onValuesChange={handleValuesChange}
				className='pt-[32px] w-[1040px] h-[224px] m-auto rounded-[12px] text-[28px] font-[700] text-[#ffffff]'
				style={{ background: 'linear-gradient(96.29deg, #228AF9 0%, #80BDFF 98.57%)' }}
			>
				<div className='ml-[43px] text-center'>{t('Get your freight quote now')}</div>

				<div className='flex items-center mt-[24px] ml-[40px] mr-[40px] pl-[16px] pr-[16px] h-[64px] rounded-[8px] bg-[#ffffff]'>
					<Form.Item noStyle name='to_city_id'>
						<ToCitySelect
							formItemKey='to_city_id'
							type='search'
							wrapperClass='flex-grow w-[100px]'
							onSelect={(v, option) => {
								setSelectedCity(option);
								intermodalRegionSelectRef.current && intermodalRegionSelectRef.current.focus();
							}}
							onUpdate={(v, option) => {
								setSelectedCity(option);
							}}
							variant='borderless'
							onClear={() => {
								searchRateContext.selectedToCity = null;
							}}
						/>
					</Form.Item>

					<Form.Item noStyle name='intermodal_region_id'>
						<IntermodalRegionSelect
							ref={intermodalRegionSelectRef}
							type='search'
							formItemKey='intermodal_region_id'
							wrapperClass='flex-grow w-[100px]'
							variant='borderless'
							onClear={() => {
								searchRateContext.selectedIntermodalRegion = null;
							}}
						/>
					</Form.Item>

					<div className=' flex items-center flex-grow w-[100px]'>
						<Form.Item noStyle name='cargo_types'>
							<CargoSelect
								formItemKey='cargo_types'
								className='flex-grow'
								wrapperClass='w-full flex'
								variant='borderless'
							/>
						</Form.Item>
					</div>

					<div className=''>
						<Form.Item noStyle name='cntr_size' initialValue={40}>
							<CntrSizeGroup formItemKey='cntr_size' />
						</Form.Item>
					</div>
				</div>

				<div className='flex mt-[15px] ml-[41px]'>
					<Form.Item valuePropName='checked' name='is_residential'>
						<ResidentialAddressCheckbox
							formItemKey='is_residential'
							className='font-[400] text-[14px] leading-[22px] text-[#ffffff]'
						/>
					</Form.Item>
				</div>

				<div className='absolute bottom-[-27px] flex justify-center items-center w-[1040px]'>
					<Form.Item noStyle>
						<div className=' relative'>
							<Badge
								count={remainingRateSearchLimit}
								offset={[-10, 10]}
								color='#FF6966'
								className={`custom-badge ${remainingRateSearchLimit > 3 && 'hidden-badge'}`}
							>
								<Button
									type='primary'
									htmlType='submit'
									className='flex items-center flex-grow-0 flex-shrink-0 pl-[24px] pr-[24px] w-[163px] h-[54px] rounded-[30px] bg-[#FFFFFF] shadow-[0px_4px_20px_0px_#FF754C26] font-[600] text-[24px] text-[#007BFF]'
									loading={isSearching}
								>
									{!isSearching && <SearchIcon active className='mr-[14px] w-[24px] h-[24px]' />}
									{t('Search')}
								</Button>
							</Badge>
							{remainingRateSearchLimit <= 3 && (
								<div className=' box-border absolute left-[175px] bottom-0 pt-[8px] pb-[8px] pl-[12px] pr-[12px] min-w-[200px] w-fit min-h-[72px] rounded-[4px] shadow-[0px_4px_10px_0px_#0000000D] text-[12px] bg-[#FFFFFF]'>
									<div className='font-[700]  text-[#FF6966]'>
										<NoticeIcon className='mr-[4px] mb-[4px] w-[16px] h-[16px]' />
										{t('Notice')}
									</div>
									<div className='font-[400] text-[#747578]'>
										<div>
											{remainingRateSearchLimit}/{rateSearchLimit} {t('searches remaining today')}{' '}
										</div>
										<div>{t('Contact customer service')}</div>
									</div>
								</div>
							)}
						</div>
					</Form.Item>
				</div>
			</Form>
		</div>
	);
});
