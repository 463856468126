import { useTranslation } from 'react-i18next';
import { Gap } from '../gap';
import {
	CreateProject1Pic,
	CreateProject3Pic,
	CreateProject4Pic,
	CreateProject5Pic,
	CreateProject6Pic,
} from '../pics';
import { SideCarouselItemProps, SideItem } from './side-item';

interface CreateProjectPanelSiderProps {
	index: number;
}

interface SideCarouselItemOptions extends SideCarouselItemProps {
	index: number[];
}

const getRenderIndex = (index: number) => {
	return index <= 2 ? index : 3;
};

export const CreateProjectPanelSider = ({ index }: CreateProjectPanelSiderProps) => {
	const { t } = useTranslation();

	const items: SideCarouselItemOptions[] = [
		{
			index: [1],
			title: t(`Let’s set up your first project!`),
			picNode: <CreateProject1Pic />,
			subTitle: t(`What is a project?`),
			content: t(
				`A project is a folder of several orders with same IR city, warehouse or within the same time range. It will be much easier for users to track and check orders with the same aspects in just one project folder.`,
			),
		},
		{
			index: [2],
			title: t(`Let’s select your project’ s criteria.`),
			picNode: null,
			subTitle: t(`What is criteria?`),
			content: t(
				`Criteria is how you want to define and categorize your project, showing under your project name. In each category , you can see different highlight info and diagrams. For example, if you select the “warehouse” criteria, you can see the info of the containers’ average out-gate time & Passing LFD rate by warerhouse.`,
			),
		},
		{
			index: [3],
			title: t(`Let’s clarify criteria details.`),
			picNode: <CreateProject3Pic />,
			subTitle: t(`POD LA`),
			content: t(
				`You can choose multiple port or ramp cities. They are the starting points of your drayage journey.`,
			),
		},
		{
			index: [4],
			title: t(`Let’s clarify criteria details.`),
			picNode: <CreateProject3Pic />,
			subTitle: t(`IR City`),
			content: t(
				`You can choose multiple port or ramp cities. They are the starting points of your drayage journey.`,
			),
		},
		{
			index: [5],
			title: t(`Let’s clarify criteria details.`),
			picNode: <CreateProject4Pic />,
			subTitle: t(`Destination`),
			content: t(`You can choose multiple destination cities or warehouses.`),
		},
		{
			index: [6],
			title: t(`Let’s clarify criteria details.`),
			picNode: <CreateProject5Pic />,
			subTitle: t(`Time Range`),
			content: t(
				`You can set up multiple time range. Orders outside of these time ranges won’t be added to the project`,
			),
		},
		{
			index: [7],
			title: t(`Let’s clarify criteria details.`),
			picNode: <CreateProject6Pic />,
			subTitle: t(`Choose Your Own MBL`),
			content: t(
				`You can choose your own orders manually with MBL numbers. You can also do so from order list.`,
			),
		},
	];

	return (
		<div className='p-[60px_32px] h-full w-full flex flex-col'>
			<div className='grow-0 shrink-0'>{`Step ${getRenderIndex(index)} of 3`}</div>
			<Gap height='10px' />
			<div className='grow shrink'>
				{items.map((item, i) => item.index.includes(index) && <SideItem key={i} {...item} />)}
			</div>
		</div>
	);
};
