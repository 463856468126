import { observer } from 'mobx-react-lite';
import OrderTable from './components/order-table';

export default observer(() => {
	return (
		<div className='p-[51px_40px_0_40px] h-screen flex flex-col'>
			<div className='flex-grow overflow-hidden'>
				<OrderTable />
			</div>
		</div>
	);
});
