import { useNavigate } from 'react-router-dom';
import { Space, Table, TableProps, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TickCircleColorIcon from '@/icons/tick-circle-color-icon';
import TickCircleIcon from '@/icons/tick-circle-icon';
import { Container, Order } from '@/interfaces/order.interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import ContainerReleaseStatusIcon from '@/components/container-release-status-icon';
import AvaliableToPickup from '@/components/container-release-status-icon/avaliable-to-pickup';
import CustomReleased from '@/components/container-release-status-icon/custom-released';
import SslReleased from '@/components/container-release-status-icon/ssl-released';
import TerminalReleased from '@/components/container-release-status-icon/terminal-released';
import ContainerStatusLabel from '@/components/container-status-label';
import { ExpandIcon } from '@/components/expand-icon';
import Risks from '../../../Risks';
import useOrderQs from '../../use-order-qs';

interface ContainersTableProps extends TableProps<Order> {
	order: Order;
}

export default observer(({ order, ...props }: ContainersTableProps) => {
	const navigate = useNavigate();

	const { changeDefaultOrderId } = useOrderQs();

	const columns: ColumnsType<Container> = [
		{
			dataIndex: ['number'],
			title: t('Container #'),
			render: (_, record) => {
				return (
					<span
						className='underline cursor-pointer'
						onClick={() => {
							changeDefaultOrderId(order.id);
							navigate(`/orders/${order.id}/containers/${record.id}`);
						}}
					>
						{record.number}
						{record.risks?.length > 0 && <Risks risks={record.risks} />}
					</span>
				);
			},
		},
		{
			dataIndex: ['lfd'],
			title: t('Last Free Day'),
			sorter: (a, b) => {
				return new Date(a.lfd ?? 0).getMilliseconds() - new Date(b.lfd ?? 0).getMilliseconds();
			},
		},
		{
			dataIndex: ['actual_terminal_pickuped_at'],
			title: t('Pickup Date'),
			sorter: (a, b) => {
				return (
					new Date(a.actual_terminal_pickuped_at ?? 0).getMilliseconds() -
					new Date(b.actual_terminal_pickuped_at ?? 0).getMilliseconds()
				);
			},
		},
		{
			dataIndex: ['actual_delivered_at'],
			title: t('Delivery Date'),
			sorter: (a, b) => {
				return (
					new Date(a.actual_delivered_at ?? 0).getMilliseconds() -
					new Date(b.actual_delivered_at ?? 0).getMilliseconds()
				);
			},
		},
		{
			dataIndex: ['actual_empty_returned_at'],
			title: t('Return Date'),
			sorter: (a, b) => {
				return (
					new Date(a.actual_empty_returned_at ?? 0).getMilliseconds() -
					new Date(b.actual_empty_returned_at ?? 0).getMilliseconds()
				);
			},
		},
		{
			dataIndex: ['freight_released_at'],
			title: t('SSL'),
			render: (_, record) => {
				return (
					<SslReleased
						actual_empty_returned_at={record?.actual_empty_returned_at}
						actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
						freight_released_at={record?.freight_released_at}
						port_of_discharge_eta={order?.port_of_discharge_eta}
					/>
				);

				// return (
				// 	<ContainerReleaseStatusIcon
				// 		time={
				// 			record?.actual_empty_returned_at || record?.actual_terminal_pickuped_at
				// 				? true
				// 				: record?.freight_released_at
				// 		}
				// 		eta={order?.port_of_discharge_eta}
				// 	/>
				// );
			},
		},
		{
			dataIndex: ['customs_released_at'],
			title: t('CSTM'),
			render: (_, record) => {
				return (
					<CustomReleased
						actual_empty_returned_at={record?.actual_empty_returned_at}
						actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
						customs_released_at={record?.customs_released_at}
						port_of_discharge_eta={order?.port_of_discharge_eta}
					/>
				);
				// return (
				// 	<ContainerReleaseStatusIcon
				// 		time={
				// 			record?.actual_empty_returned_at || record?.actual_terminal_pickuped_at
				// 				? true
				// 				: record?.customs_released_at
				// 		}
				// 		eta={order?.port_of_discharge_eta}
				// 	/>
				// );
			},
		},
		{
			dataIndex: ['terminal_released_at'],
			title: t('TRM'),
			render: (_, record) => {
				return (
					<TerminalReleased
						actual_empty_returned_at={record?.actual_empty_returned_at}
						actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
						terminal_released_at={record?.terminal_released_at}
						port_of_discharge_eta={order?.port_of_discharge_eta}
					/>
				);
				// return (
				// 	<ContainerReleaseStatusIcon
				// 		time={
				// 			record?.actual_empty_returned_at || record?.actual_terminal_pickuped_at
				// 				? true
				// 				: record?.terminal_released_at
				// 		}
				// 		eta={order?.port_of_discharge_eta}
				// 	/>
				// );
			},
		},
		{
			dataIndex: ['ready_to_pickup_at'],
			title: t('AVAL'),
			render: (_, record) => {
				return (
					<AvaliableToPickup
						actual_empty_returned_at={record?.actual_empty_returned_at}
						actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
						ready_to_pickup_at={record?.ready_to_pickup_at}
						port_of_discharge_eta={order?.port_of_discharge_eta}
					/>
				);
				// return (
				// 	<ContainerReleaseStatusIcon
				// 		time={
				// 			record?.actual_empty_returned_at || record?.actual_terminal_pickuped_at
				// 				? true
				// 				: record?.ready_to_pickup_at
				// 		}
				// 		eta={order?.port_of_discharge_eta}
				// 	/>
				// );
			},
		},
		{
			dataIndex: ['status_text'],
			title: t('CNTR Status'),
			// align: 'center',
			// render: (statusText) => <ContainerStatusLabel status={statusText} />,
			render: (statusText) => <>{t(statusText)}</>,
		},
		Table.EXPAND_COLUMN,
	];

	return (
		<Table
			columns={columns}
			dataSource={order.containers}
			pagination={false}
			expandable={{
				expandIcon: ExpandIcon,
				expandedRowRender: () => <></>,
				expandedRowClassName: () => 'hidden',
				expandRowByClick: false,
				onExpand: (_, record) => {
					navigate(`/orders/${order.id}/containers/${record.id}`);
				},
			}}
		/>
	);
});
