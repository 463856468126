import { useState } from 'react';
import { Form, Input, message } from 'antd';
import { LoadingOutlined, SendOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

interface MessageSenderProps {
	onClick: (values: { body: string }) => any;
}

export default observer(({ onClick }: MessageSenderProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const [form] = Form.useForm();

	const handleFinish = async () => {
		if (isLoading) return;
		setIsLoading(true);

		const values = form.getFieldsValue();

		try {
			await onClick(values);
			message.success(t('send message successfully'));
		} finally {
			form.resetFields();
			setIsLoading(false);
		}
	};

	return (
		<Form form={form} className='p-[16px_24px_24px_24px] h-[104px] cursor-pointer'>
			<div className='p-[8px_8px_8px_16px] h-[64px] flex justify-between items-center border-[1px] border-solid border-grey-02 rounded-[4px] text-grey-02 shadow-[0px_4px_10px_0px_#0000000D] '>
				<Form.Item noStyle name='body'>
					<Input.TextArea variant='borderless' autoSize={{ minRows: 2, maxRows: 2 }} />
				</Form.Item>
				<div
					className='w-[48px] h-[48px] flex-center bg-primary-regular rounded-[4px]'
					onClick={handleFinish}
				>
					{isLoading ? (
						<LoadingOutlined className='text-white text-[26px]' />
					) : (
						<SendOutlined className='text-white text-[26px]' />
					)}
				</div>
			</div>
		</Form>
	);
});
