import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Table, TableColumnsType } from 'antd';
import { getTransactionLogs } from '@/services/apis';
import { TransactionLog as TTransactionLog } from '@/services/apis/get-prepay-orders/interface';
import { t } from 'i18next';

const renderDiff = (v1: number, v2: number) => {
	const diff = v1 - v2;
	return diff ? (
		<>
			{/* {v1.toFixed(2)} */}
			<span className={['ml-sm', diff > 0 ? 'text-[#52C41A]' : 'text-[#FF4D4F]'].join(' ')}>
				({diff > 0 ? '+' : '-'}
				{Math.abs(diff).toFixed(2)})
			</span>
		</>
	) : (
		'-'
	);
};

export const TransactionLog: React.FC<{
	order_id?: number;
	container_id?: number;
}> = ({ order_id, container_id }) => {
	const [data, setData] = useState([]);
	const fetchTransactionLogs = async () => {
		try {
			const resp = await getTransactionLogs({
				order_id,
				container_id,
			});
			setData(resp.data.data);
		} catch (err: any) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchTransactionLogs();
	}, []);

	const filterdData = useMemo(() => {
		return data;
	}, [data]);

	const columns = useMemo(() => {
		const columns: TableColumnsType<TTransactionLog> = [
			{
				title: t('Time'),
				dataIndex: 'created_at',
			},
			{
				title: t('Operation'),
				dataIndex: 'op',
				render: (v) => t(v),
			},
			// {
			// 	title: 'Balance',
			// 	align: 'right',
			// 	render: (record: TTransactionLog) =>
			// 		renderDiff(+record.current_balance_amount, +record.prev_balance_amount),
			// },
			{
				title: t('Hold'),
				dataIndex: 'current_hold_amount',
				align: 'right',
				render: (v, record) => renderDiff(+v, +record.prev_hold_amount),
			},
			{
				title: t('Settled'),
				dataIndex: 'current_settled_amount',
				align: 'right',
				render: (v, record) => renderDiff(+v, +record.prev_settled_amount),
			},
		];
		return columns;
	}, []);

	return (
		<>
			<Table
				pagination={false}
				rowKey='id'
				size='small'
				columns={columns}
				dataSource={filterdData}
			/>
		</>
	);
};
