import { useCallback, useEffect, useState } from 'react';
import { message, Pagination, PaginationProps } from 'antd';
import { DraftOrder } from '@/interfaces/draft-order.interface';
import { useOrderContext } from '@/services/contexts';
import { t } from 'i18next';
import AllDraftOrderDrawer from './all-draft-order-drawer';

export default () => {
	const pageSize = 6;

	const [open, setOpen] = useState(false);

	const [currentIndex, setCurrentIndex] = useState(1);

	const [draftOrders, setDraftOrders] = useState<DraftOrder[]>([]);

	const [total, setTotal] = useState(0);

	const [fetching, setFetching] = useState(false);

	const orderContext = useOrderContext();

	const openDraftOrder = useCallback(() => {
		setOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setOpen(false);
	}, []);

	const getDraftOrders = async (page: number, pageSize: number) => {
		setFetching(true);
		try {
			const res = await orderContext.fetchDraftOrders(
				{ params: { page, per_page: pageSize } },
				{ autoSet: false },
			);
			setDraftOrders(res.data);
			setTotal(res.meta.total);
		} catch (error) {
			message.error(t('error'));
		} finally {
			setFetching(false);
		}
	};

	const handlePaginationChange: PaginationProps['onChange'] = async (page, pageSize) => {
		await getDraftOrders(page, pageSize);
		setCurrentIndex(page);
	};

	// 根据接口设置 total
	const Footer = () => (
		<div className='flex justify-center'>
			<Pagination
				defaultCurrent={currentIndex}
				total={total}
				pageSize={pageSize}
				onChange={handlePaginationChange}
			/>
		</div>
	);

	const Drawer = (
		<AllDraftOrderDrawer
			width={894}
			open={open}
			fetching={fetching}
			draftOrders={draftOrders}
			footer={<Footer />}
			onClose={closeModal}
		/>
	);

	useEffect(() => {
		handlePaginationChange(1, pageSize);
	}, []);

	return {
		openDraftOrder,
		closeModal,
		Drawer,
	};
};
