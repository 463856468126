import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { RequestedRatesProps, RequestedRatesRes } from './interface';

export const getRequestedRates = async (props?: RequestedRatesProps) => {
	return await axios.get<RequestedRatesRes>(`/requestedRates`, {
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
		params: props?.params,
	});
};
