import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

export default observer(({ className, ...props }: ButtonProps) => {
	return (
		<Button
			{...props}
			type='primary'
			ghost
			className={clsx('w-[212px] h-[54px] rounded-[10px] font-[700] text-[20px]', className)}
		>
			{t('Save As Draft')}
		</Button>
	);
});
