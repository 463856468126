import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, ModalProps, Row } from 'antd';

export const useContactModal = () => {
	const [openingContactModal, setOpeningContactModal] = useState(false);

	const openContactModal = useCallback(() => {
		setOpeningContactModal(true);
	}, [setOpeningContactModal]);

	const closeContactModal = useCallback(() => {
		setOpeningContactModal(false);
	}, [setOpeningContactModal]);

	return {
		openingContactModal,
		openContactModal,
		closeContactModal,
	};
};

export const ContactModal = (options?: ModalProps & { onClose?: () => void }) => {
	const { t } = useTranslation();

	return (
		<Modal closable centered footer={null} onCancel={options?.onClose} {...options}>
			<div className='mb-[45px]'>
				<div className='mb-[5px] font-[700] text-[16px]'>{t('Need assistance?')}</div>
				<p className='mb-[5px]'>{t('No worry! We can help!')}</p>
				<div>
					<p>
						<label className='mr-[4px]'>{t('Phone')}: </label> 1-818-826-2850
					</p>
					<p>
						<label className='mr-[4px]'>{t('Email')}: </label>sales@drayeasy.com
					</p>
				</div>
			</div>

			<div>
				<div className='mb-[5px] font-[700] text-[16px]'>{t('Customer service hours')}</div>
				<Row gutter={16} className='mb-[5px]'>
					<Col span={4}>
						<p>{t('7/24 Support')}</p>
					</Col>
				</Row>
			</div>
		</Modal>
	);
};
