import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalProps, Spin, Tabs, TabsProps } from 'antd';
import { ProjectProgressContainer } from '@/services/apis';
import { downloadFile } from '@/services/apis/download-file';
import { WarningOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { useMount, useSafeState } from 'ahooks';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import AvaliableToPickup from '@/components/container-release-status-icon/avaliable-to-pickup';
import CustomReleased from '@/components/container-release-status-icon/custom-released';
import SslReleased from '@/components/container-release-status-icon/ssl-released';
import TerminalReleased from '@/components/container-release-status-icon/terminal-released';
import { Gap } from '@/components/gap';
import { ProgressTable } from '@/components/progress-table';
import { ProjectExceptionWarning } from '@/components/project-exceptions/exception-warning';
import { Table, TableProps } from '@/components/table';
import Risks from '@/pages/order/components/Risks';
import {
	ProgressLabel,
	ProgressMapKeysEnum,
	useProgressMap,
} from '@/pages/project/use-progress-map';
import { useProjectCalendarProgress } from './use-project-calendar-progress';

export * from './use-progress-tracking-modal';

interface ProcessTrackingModalProps extends ModalProps {
	progressKey: ProgressMapKeysEnum;
	projectId: number;
	date: string;
	onClose: () => void;
}

export const ProcessTrackingModal = ({
	progressKey,
	projectId,
	date,
	onClose,
	...modalProps
}: ProcessTrackingModalProps) => {
	const { t } = useTranslation();

	const { data, pagination, loading, requestNextPage } = useProjectCalendarProgress(
		progressKey,
		projectId,
		date,
	);

	return (
		<Modal width={1248} footer={null} onCancel={() => onClose()} {...modalProps}>
			<ProgressTable
				progressKey={progressKey}
				projectId={projectId}
				loading={loading ?? false}
				dataSource={data ?? []}
				alwaysShowPodAndEir={true}
				onNextPage={requestNextPage}
			/>
		</Modal>
	);
};
