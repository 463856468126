import { getProjectContainersMap } from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetProjectContainersMap = (options?: Record<any, any>) => {
	const res = useRequest(getProjectContainersMap, {
		onError: (error) => {
			message.error(`${error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res?.data?.data ?? { sailing_on_the_sea_total: 0, arrived_at_port: [] },
	};
};
