import { Button, Dropdown, MenuProps, Popconfirm } from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { t } from 'i18next';

interface ModifyDropdownProps {
	onRemove: () => void;
}

export default ({ onRemove }: ModifyDropdownProps) => {
	const modifyItems: MenuProps['items'] = [
		{
			key: t('Delete'),
			label: (
				<Popconfirm
					title={t('Are you sure to delete this container')}
					onConfirm={() => onRemove()}
					okText={t('Yes')}
					cancelText={t('No')}
				>
					<DeleteOutlined className=' text-updated-red' />
					{t('Delete')}
				</Popconfirm>
			),
		},
	];

	return (
		<div>
			<Dropdown menu={{ items: modifyItems }}>
				<Button type='text'>
					<EllipsisOutlined />
				</Button>
			</Dropdown>
		</div>
	);
};
