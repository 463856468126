import { useCallback } from 'react';
import { Button, DatePicker, Divider, Form, FormProps } from 'antd';
import { CONTAINER_STATUS_MAP } from '@/services/contexts/container/constant';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import Input from '@/components/input';
import Select from '@/components/select';
import useOrderQs from './use-order-qs';

const Item = Form.Item;

interface HeaderProps {
	onSearch: (values: any) => void;
}

export default observer(({ onSearch }: HeaderProps) => {
	const { parsedSearchParams, changeSearchParams } = useOrderQs();

	const [form] = Form.useForm();

	const doSearch = useCallback(debounce(onSearch, 500), []);

	// 表单每次更改，都触发请求，并修改 search params
	const handleValueChange: FormProps['onValuesChange'] = async (_, values) => {
		doSearch({ ...values, page: 1 });
	};

	const resetFilter = () => {
		form.resetFields();
		changeSearchParams({});
		handleValueChange(null, {});
	};

	return (
		<div className='flex justify-between items-center'>
			{/* left */}
			<div>
				<Form
					className='flex'
					layout='vertical'
					form={form}
					initialValues={parsedSearchParams}
					onValuesChange={handleValueChange}
				>
					<Item label={t('Search')} name='query'>
						<Input
							allowClear
							className='mr-[16px] w-[320px]'
							prefix={<SearchOutlined />}
							placeholder={t('Search for MBL#, Container number...')}
						/>
					</Item>

					<Item label={t('CNTR Status')} name='container_status' className='w-[240px]'>
						<Select
							allowClear
							mode='multiple'
							popupMatchSelectWidth
							maxTagCount='responsive'
							options={
								Object.keys(CONTAINER_STATUS_MAP).map((key) => ({
									label: t(CONTAINER_STATUS_MAP[key]),
									value: key,
								})) as any
							}
						/>
					</Item>

					<Item label={t('Order date from')} name='created_at_start_date'>
						<DatePicker className='light-border ml-[16px] mr-[8px]' />
					</Item>

					<Item label=' '>
						<Divider className='m-0 mr-[8px] min-w-[16px] w-[16px]' />
					</Item>

					<Item label={t('Order date to')} name='created_at_end_date'>
						<DatePicker className='mr-[34px] light-border' />
					</Item>

					<Item label=' '>
						<Button type='link' icon={<SyncOutlined />} onClick={resetFilter}>
							{t('Reset filters')}
						</Button>
					</Item>
				</Form>
			</div>

			{/* right */}
			{/* <div>
				<Button type='primary' ghost className='flex items-center'>
					{t('Export')}
					<ExportIcon />
				</Button>
			</div> */}
		</div>
	);
});
