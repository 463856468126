import { useEffect, useState } from 'react';
import { Select as AntdSelect, Empty, Form, SelectProps } from 'antd';
import DestinationIcon from '@/icons/destination-icon';
import { City } from '@/interfaces/city.interface';
import { useSearchRateContext } from '@/services/contexts';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import Select from '@/components/select';
import { ToCitySelectProps } from './interface';

export const ToCitySelect = observer(
	({
		formItemKey,
		type,
		defaultCities,
		defaultValue,
		className,
		wrapperClass,
		dropdownStyle,
		fixedOptions,
		onSelect,
		disableContext,
		onUpdate,
		...props
	}: ToCitySelectProps) => {
		const isSearch = type === 'search';

		const form = Form.useFormInstance();
		const searchRateContext = useSearchRateContext();

		const [fetching, setFetching] = useState(false);

		const [toCities, setToCities] = useState<City[]>(defaultCities ?? []);

		const [searchText, setSearchText] = useState('');

		const options = toCities.map((o) => ({
			label: o.full_name,
			value: o.id,
		}));

		/**
		 * 搜索匹配的港口
		 * @param searchText
		 */
		const { run: handleSearch } = useDebounceFn(
			async (searchText: string) => {
				let fetchIndex = 0;

				const _handleSearch = async (searchText: string) => {
					if (searchText === '') return;
					fetchIndex += 1;
					const _fetchIndex = fetchIndex;
					setFetching(true);
					setSearchText(searchText);
					const res = await searchRateContext.searchToCity(searchText);
					setFetching(false);
					if (_fetchIndex !== fetchIndex) return;

					setToCities(res.data.data ? [...res.data.data] : []);
				};

				return _handleSearch(searchText);
			},
			{ wait: 500 },
		);

		const handleSelect: SelectProps['onSelect'] = (newValue: number, option?: any) => {
			onSelect && onSelect(newValue, option);
			form.setFieldValue(formItemKey, newValue);
			if (!disableContext) {
				searchRateContext.selectedToCity = toCities.find((c) => c.id === newValue);
			}
		};

		const EmptyCity = () =>
			!searchText || searchText === '' ? (
				<div className='pt-[8px] pl-[16px]'>{t('Please search city')}</div>
			) : (
				<Empty description={`${t("DrayEasy can't find")} ${searchText}`} />
			);

		useEffect(() => {
			if (
				isSearch &&
				searchRateContext.selectedToCity &&
				searchRateContext.selectedToCity.id !== form.getFieldValue(formItemKey)
			) {
				if (disableContext) return;
				form.setFieldValue(formItemKey, searchRateContext.selectedToCity.id);
				setToCities([{ ...searchRateContext.selectedToCity }]);
				onUpdate && onUpdate(searchRateContext.selectedToCity.id, searchRateContext.selectedToCity);
			}
		}, [searchRateContext.selectedToCity]);

		useEffect(() => {
			if (defaultValue) {
				handleSelect(defaultValue, defaultValue);
			}
		}, []);

		return (
			<div className={clsx('leading-[0px] flex items-center', wrapperClass)}>
				<DestinationIcon className=' w-[24px] h-[24px]' />
				<Select
					{...props}
					className={clsx('ml-[-5px] w-full', className)}
					filterOption={false}
					showSearch
					allowClear
					optionLabelProp='label'
					placeholder={`${t('Warehouse City')} / ${t('Zipcode')}`}
					style={{ textAlign: 'left' }}
					dropdownStyle={{
						borderRadius: '0 0 8px 8px',
						paddingBottom: '20px',
					}}
					notFoundContent={fetching ? <CenterSpin size='small' /> : <EmptyCity />}
					onSearch={handleSearch}
					onSelect={(value, option) => {
						handleSelect(value, option);
					}}
				>
					{/* @ts-ignore */}
					{(fixedOptions || options).map((option) => (
						<AntdSelect.Option key={option.value} value={option.value} label={option.label}>
							{option.label}
						</AntdSelect.Option>
					))}
				</Select>
			</div>
		);
	},
);
