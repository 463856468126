import { Checkbox, Form, Tag } from 'antd';
import clsx from 'clsx';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';
import { CargoTypeSelectProps } from './type';

export default observer(function ({ formItemKey, className, ...props }: CargoTypeSelectProps) {
	const form = Form.useFormInstance();

	const isSelected = (v: number) => (form.getFieldValue(formItemKey) ?? []).includes(v);

	const LabelRender = ({ label, value }: { label: string; value: number }) => (
		<div>
			<Checkbox checked={isSelected(value)} />
			{label}
		</div>
	);

	const options = [
		{
			label: <LabelRender label={t('DG')} value={2} />,
			labelContent: t('DG'),
			value: 2,
		},
		{
			label: <LabelRender label={t('RF')} value={3} />,
			labelContent: t('RF'),
			value: 3,
		},
		// {
		// 	label: <LabelRender label={t('SOC')} value={5} />,
		// 	labelContent: t('SOC'),
		// 	value: 5,
		// },
		{
			label: <LabelRender label={t('OW')} value={4} />,
			labelContent: t('OW'),
			value: 4,
		},
	];

	/**
	 * 标签渲染
	 * @param props
	 * @returns
	 */
	const TagRender = (props: any) => {
		const { closable, onClose, value } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		const _label = options.find((o) => o.value === value)?.labelContent;
		return (
			<Tag
				className='bg-[#F5FAFF] text-[#3A92F7] border-[1px] border-solid leading-[34px] text-center border-[#DBEDFF] rounded-[200px] font-[400] text-[14px] w-fit h-[36px]'
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={(e) => {
					onClose(e);
				}}
				style={{ marginRight: 3 }}
			>
				{_label}
			</Tag>
		);
	};

	return (
		<Select
			{...props}
			className={clsx(className)}
			defaultValue={[] as number[]}
			mode='multiple'
			options={options}
			tagRender={(tagProps) => <TagRender {...tagProps} />}
			maxTagCount='responsive'
		/>
	);
});
