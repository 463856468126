import accessTokenManager from '../contexts/user/access-token-manager';

export const downloadFactory = (
	url: string,
	fileName: string | number,
	config?: { preview?: boolean },
) => {
	fetch(url, { headers: { Authorization: accessTokenManager.getAccessToken() } })
		.then((response) => response.blob())
		.then((blob) => {
			const fileUrl = URL.createObjectURL(blob);

			if (config?.preview) {
				window.open(fileUrl, '_blank');
			} else {
				const a = document.createElement('a');
				a.href = fileUrl;
				a.download = String(fileName);
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(fileUrl);
				document.body.removeChild(a);
			}
		});
};
