import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import './index.less';
import { MenuGroupProps } from './interface';

export const MenuGroup = ({ name, items }: MenuGroupProps) => {
	const navigate = useNavigate();

	// 自动匹配路由
	const getSelectedKey = () => {
		const currentPath = window.location.pathname;
		const selectedKeys: string[] = [];

		items?.forEach((i) => {
			if (i === null || i.key === undefined) return;
			if (currentPath.startsWith(`/${i.key}`)) {
				selectedKeys.push(String(i.key));
			}
		});
		return selectedKeys;
	};

	return (
		<div className='menu-group'>
			{/* group name */}
			<div className='menu-group-name'>{name}</div>
			{/* menu */}
			<Menu
				mode={'vertical'}
				onClick={({ key }) => {
					// language 不是 menu
					if (['setting', 'zh-CN', 'en-US'].includes(key)) return;

					navigate(key);
				}}
				items={items}
				selectedKeys={getSelectedKey()}
			/>
		</div>
	);
};
