import { Col, Row } from 'antd';
import { useBookContext, useSearchRateContext } from '@/services/contexts';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import FeeTable from '@/components/fee-table';
import { PossibleCharges } from '@/pages/search-rate/search-rate-result/components/possible-charges';
import BottomButtons from '../bottom-buttons';
import Panel from '../panel';
import { Title } from '../title';
import OrderInfoTimeline from './order-info-timeline';
import { ConfirmationProps } from './type';

export const Confirmation = observer(
	({ OrderButton, SaveDraftButton, BackButton, goStep }: ConfirmationProps) => {
		const searchRateContext = useSearchRateContext();

		const bookContext = useBookContext();

		const scenarios = bookContext.useDraftOrder
			? bookContext.draftOrder?.scenarios
			: searchRateContext.selectedSearchData?.scenarios;
		const possibleCharges = bookContext.useDraftOrder
			? bookContext.draftOrder?.possible_charges
			: searchRateContext.selectedSearchData?.possible_charges;

		searchRateContext.selectedSearchData?.base_rate;

		const footer = (
			<BottomButtons
				leftButton={<BackButton />}
				rightButton1={<SaveDraftButton />}
				rightButton2={<OrderButton />}
				rightButton2Message={
					<div className='h-[22px] text-[#FF0000] text-[14px] font-[400]'>{` `}</div>
				}
			/>
		);

		return (
			<Panel footer={footer}>
				<Row gutter={80} className='h-full'>
					<Col span={12}>
						<Title title={t('Order info')} />
						<OrderInfoTimeline goStep={goStep} />
					</Col>

					<Col span={12} className='flex flex-col'>
						<Title title={t('Price')} />
						<div className='border-box p-[24px] border-[1px] border-solid border-[#E7E7E7] rounded-[10px]'>
							<FeeTable scenarios={scenarios} />
						</div>
						<div className='mt-[10px] min-h-[250px] flex-grow'>
							<PossibleCharges
								rate={searchRateContext?.selectedSearchData?.truck_rate}
								title={t('Accessorial Charges')}
								possible_charges={possibleCharges}
								showProbability
							/>
						</div>
					</Col>
				</Row>
			</Panel>
		);
	},
);
