import { getProject } from '@/services/apis';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetProject = (options?: Record<any, any>) => {
	const res = useRequest(getProject, {
		onError: (error) => {
			message.error(`${error.message}`);
		},
		...options,
	});

	return {
		...res,
		data: res.data?.data.data,
	};
};
