import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { t } from 'i18next';
import { toJS } from 'mobx';

export default () => {
	const [open, setOpen] = useState(false);
	const [order, setOrder] = useState<Order>();

	const navigate = useNavigate();

	const openModal = (order: Order) => {
		setOrder(order);
		setOpen(true);
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpen(false);
	};

	const ExpiredModal = () => {
		return (
			<Modal
				centered
				open={open}
				title={t('rate expired')}
				onCancel={closeModal}
				onOk={() => {
					if (!order) return;
					const navUrl = `/search-rate?order_id=${order.id}&to_city_id=${order.warehouse.city.id}&intermodal_region_id=${order.final_port.id}`;
					navigate(navUrl);
				}}
				okText={t('Confirm')}
			>
				<p>
					{t(
						`The previous rate for this order has expired. Please confirm if you accept the new rate, which might have a slight change.`,
					)}
				</p>
			</Modal>
		);
	};

	return {
		openModal,
		closeModal,
		ExpiredModal,
	};
};
