import { ReactNode } from 'react';
import UserInterfaceIcon from '@/icons/user-interface-icon';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

interface TableTooltipProps {
	icon: ReactNode;
	title?: ReactNode;
	content: ReactNode;
	tooltipColor: string;
	bgColor: string;
	className?: string;
}

export default observer(
	({ icon, title, content, tooltipColor, bgColor, className }: TableTooltipProps) => {
		return (
			<div className={clsx('flex h-fit w-[100%] ', className)}>
				<div
					className={clsx(`min-h-[32px] w-[5px] rounded-[4px_0_0_4px]`)}
					style={{ backgroundColor: tooltipColor }}
				></div>

				<div
					className={`flex-grow p-[16px_8px] h-full flex items-baseline `}
					style={{ backgroundColor: bgColor }}
				>
					<div className='mr-[10px]'>{icon}</div>
					<div>
						<div>{title}</div>
						<div className='font-[400] text-[12px] text-[#8F90A6]'>{content}</div>
					</div>
				</div>
			</div>
		);
	},
);
