// 参数值
export type StatusKeys = 'completed' | 'in_progress' | 'rejected';

interface Status {
	bgColor: string;
	label: string;
}

const StatusMap: Record<StatusKeys, Status> = {
	completed: {
		bgColor: 'bg-success-700',
		label: 'Completed',
	},
	in_progress: {
		bgColor: 'bg-blue-500',
		label: 'In Progress',
	},
	rejected: {
		bgColor: 'bg-warning-600',
		label: 'Rejected',
	},
};

export default ({ statusKey }: { statusKey: StatusKeys }) => (
	<div className='flex items-center whitespace-nowrap'>
		<div
			className={`mr-[6px] w-[7px] h-[7px] rounded-[50%] ${StatusMap[statusKey].bgColor} text-[16px] text-[#212121]`}
		/>
		{StatusMap[statusKey].label}
	</div>
);
