import { useMemo, useState } from 'react';
import { Button, Space, Tabs, TabsProps } from 'antd';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { downloadInvoice } from '@/services/apis';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { t } from 'i18next';
import { Tag } from '@/components/tag';
import { BillContent } from './bill-content';

interface BillingProps {
	container: ContainerDetail;
}

// TODO
// 1. issue on
// 2. bill 数据
export default ({ container }: BillingProps) => {
	const [isHideNote, setIsHideNote] = useState(false);

	return (
		<div>
			{container.invoices.length > 1 ? (
				<Tabs
					defaultActiveKey={container.invoices[0]?.uid}
					items={container.invoices.map((invoice) => ({
						key: invoice.uid,
						label: invoice.uid,
						children: <BillContent container={container} invoice={invoice} />,
					}))}
				/>
			) : (
				container.invoices.length == 1 && (
					<BillContent container={container} invoice={container.invoices[0]} />
				)
			)}
			{/* note */}
			<div
				className={clsx(
					'relative p-[16px] border-[2px] border-solid border-grey-05 rounded-[6px] bg-grey-06',
					{ hidden: isHideNote },
				)}
			>
				<Button
					type='text'
					icon={<CloseOutlined />}
					onClick={() => setIsHideNote(true)}
					className=' absolute top-[5px] right-[5px] text-grey-01'
				/>
				<div className='body-4-b'>{t('How to pay my bill?')}</div>
				<p className='body-4-r'>
					{t(
						'Please reach out to our customer service team who will provide you with the necessary account details and instructions for making the payment.',
					)}
				</p>
			</div>
		</div>
	);
};
