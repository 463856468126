import { forwardRef, useEffect, useState } from 'react';
import React from 'react';
import { Form } from 'antd';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import PopularBlueIcon from '@/icons/popular-blue-icon';
import { IntermodalRegion } from '@/interfaces/intermodal-region.interface';
import { useGlobalContext, useSearchRateContext } from '@/services/contexts';
import clsx from 'clsx';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select';
import CenterSpin from '@/components/center-spin';
import Select from '@/components/select';
import { IntermodalRegionSelectProps } from './type';

export const IntermodalRegionSelect = observer(
	forwardRef<BaseSelectRef, IntermodalRegionSelectProps>(
		(
			{
				formItemKey,
				type,
				defaultIntermodalRegions,
				defaultValue,
				wrapperClass,
				className,
				disableContext,
				...props
			},
			ref,
		) => {
			const isSearch = type === 'search';

			const form = Form.useFormInstance();
			const searchRateContext = useSearchRateContext();
			const toCityId = Form.useWatch('to_city_id');
			/**
			 * 处理 portal 的开关
			 */
			const [open, setOpen] = useState(false);

			const [fetching, setFetching] = useState(false);

			const [searchText, setSearchText] = useState('');

			const [intermodalRegions, setIntermodalRegions] = useState<IntermodalRegion[]>(
				defaultIntermodalRegions ?? [],
			);

			const { cacheContext } = useGlobalContext();

			const options = intermodalRegions
				.filter((o) => o.name.toLowerCase().includes(searchText.toLowerCase()))
				.map((o) => ({
					label: o.name,
					labelContent: o.name,
					value: o.id,
				}));

			const recommendIRIds = options.map((o) => o.value);

			const cachedOptions = cacheContext.intermodalRegions
				.filter((o) => !recommendIRIds.includes(o.id))
				.filter((o) => o.name.toLowerCase().includes(searchText.toLowerCase()))
				.map((o) => ({
					label: o.name,
					labelContent: o.name,
					value: o.id,
				}));

			const handleSelect = (newValue: number) => {
				form.setFieldValue(formItemKey, newValue);
				const selectedIntermodalRegion = intermodalRegions.find((i) => i.id == newValue);
				if (!disableContext) {
					searchRateContext.selectedIntermodalRegion = selectedIntermodalRegion;
				}
				setOpen(false);
			};

			const handleSearch = (searchText: string) => {
				setSearchText(searchText);
			};

			const fetchRecommendIntermodalRegions = async (toCityId: number) => {
				setFetching(true);
				const res = await searchRateContext.searchIntermodalRegionByCity(toCityId);
				const data = res.data?.data ?? [];
				setIntermodalRegions([...data]);
				setFetching(false);
			};

			const OptionRender = ({
				label,
				labelContent,
				value,
				isRecommend,
			}: {
				label: string;
				labelContent: string;
				value: number;
				isRecommend?: boolean;
			}) => {
				return (
					<div
						className='flex items-center mb-[12px] cursor-pointer'
						onClick={() => handleSelect(value)}
					>
						{isRecommend ? (
							<React.Fragment>
								<PopularBlueIcon className='mr-[16px] w-[16px] h-[20px]' />
								<div>
									<div>{labelContent}</div>
									<div className='text-[#B5B8D2] leading-[18px] font-[400] text-[13px]'>
										{t('most popular choice')}
									</div>
								</div>
							</React.Fragment>
						) : (
							<div>{labelContent}</div>
						)}
					</div>
				);
			};

			useEffect(() => {
				if (defaultValue) {
					handleSelect(defaultValue);
				}
			}, []);

			useEffect(() => {
				if (toCityId === undefined || toCityId === null) return;
				fetchRecommendIntermodalRegions(toCityId);
			}, [toCityId]);

			useEffect(() => {
				if (disableContext) return;
				if (
					isSearch &&
					searchRateContext.selectedIntermodalRegion &&
					searchRateContext.selectedIntermodalRegion.id !== form.getFieldValue(formItemKey)
				) {
					form.setFieldValue(formItemKey, searchRateContext.selectedIntermodalRegion.id);
					setIntermodalRegions([{ ...searchRateContext.selectedIntermodalRegion }]);
				}
			}, [searchRateContext.selectedIntermodalRegion]);

			return (
				<div className={clsx('flex items-center', wrapperClass)}>
					<CircleBlueFillIcon className=' w-[24px] h-[24px]' />
					<Select
						{...props}
						className={clsx('flex-grow w-full', className)}
						ref={ref}
						open={open}
						showSearch
						allowClear
						optionLabelProp='label'
						placeholder={`${t('Port')} / ${t('Ramp')}`}
						style={{ textAlign: 'left' }}
						dropdownStyle={{
							borderRadius: '0 0 8px 8px',
							padding: '16px 16px 0 16px',
						}}
						dropdownRender={() =>
							fetching ? (
								<CenterSpin size='small' />
							) : (
								<div className='h-fit max-h-[30vh] overflow-scroll'>
									{options.map((o) => (
										<OptionRender key={o.value} {...o} isRecommend />
									))}
									{cachedOptions.map((o) => (
										<OptionRender key={o.value} {...o} />
									))}
								</div>
							)
						}
						onDropdownVisibleChange={(visible) => setOpen(visible)}
						options={[...options, ...cachedOptions]}
						notFoundContent={fetching ? <CenterSpin size='small' /> : null}
						onClear={() => {
							if (disableContext) return;
							searchRateContext.selectedIntermodalRegion = undefined;
						}}
						onSearch={handleSearch}
						onFocus={() => setOpen(true)}
						filterOption={false}
					/>
				</div>
			);
		},
	),
);
