import { SearchRatesResData } from '@/services/apis/search-rates/interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

interface CompanyProfileProps {
	searchData: SearchRatesResData;
}

export const CompanyProfile = observer(({ searchData }: CompanyProfileProps) => {
	return (
		<div className='w-[360px]'>
			{!!searchData.vendor_logo_url && (
				<img className='h-[120px]' src={searchData.vendor_logo_url} alt='logo' />
			)}
			{!!searchData.vendor_address && (
				<p>
					<span className='font-bold'>{t('Address')}</span>: {searchData.vendor_address}
				</p>
			)}
			{!!searchData.vendor_overview && (
				<p>
					<span className='font-bold'>{t('Overview')}</span>: {searchData.vendor_overview}
				</p>
			)}
			{!!searchData.vendor_website_url && (
				<p>
					<span className='font-bold'>{t('Website')}</span>: {searchData.vendor_website_url}
				</p>
			)}
			{!!searchData.vendor_us_dot && (
				<p>
					<span className='font-bold'>{t('US DOT#')}</span>: {searchData.vendor_us_dot}
				</p>
			)}
			{!!searchData.vendor_trucker_mc && (
				<p>
					<span className='font-bold'>{t('MC#')}</span>: {searchData.vendor_trucker_mc}
				</p>
			)}
			{!!searchData.vendor_scac_code && (
				<p>
					<span className='font-bold'>{t('SCAC#')}</span>: {searchData.vendor_scac_code}
				</p>
			)}
			{!!searchData.vendor_contact && (
				<p>
					<span className='font-bold'>{t('Contact')}</span>: {searchData.vendor_contact}
				</p>
			)}
			{!!searchData.vendor_phone && (
				<p>
					<span className='font-bold'>{t('Phone')}</span>: {searchData.vendor_phone}
				</p>
			)}
			{!!searchData.vendor_email && (
				<p>
					<span className='font-bold'>{t('Email')}</span>: {searchData.vendor_email}
				</p>
			)}
			{!!searchData.vendor_wechat && (
				<p>
					<span className='font-bold'>{t('Wechat')}</span>: {searchData.vendor_wechat}
				</p>
			)}
		</div>
	);
});
