import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalProps, Spin, Tabs, TabsProps } from 'antd';
import { ProjectProgressContainer } from '@/services/apis';
import { downloadFile } from '@/services/apis/download-file';
import { WarningOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { useMount, useSafeState } from 'ahooks';
import { table } from 'console';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import AvaliableToPickup from '@/components/container-release-status-icon/avaliable-to-pickup';
import CustomReleased from '@/components/container-release-status-icon/custom-released';
import SslReleased from '@/components/container-release-status-icon/ssl-released';
import TerminalReleased from '@/components/container-release-status-icon/terminal-released';
import { Gap } from '@/components/gap';
import { ProgressTable } from '@/components/progress-table';
import { ProjectExceptionWarning } from '@/components/project-exceptions/exception-warning';
import { Table, TableProps } from '@/components/table';
import Risks from '@/pages/order/components/Risks';
import {
	ProgressLabel,
	ProgressMapKeysEnum,
	useProgressMap,
} from '@/pages/project/use-progress-map';
import { useProjectProgress } from '../../overview-panel/container-tracking-panel/progress-panel/use-project-progress';

export * from './use-progress-tracking-modal';

interface ProcessTrackingModalProps extends ModalProps {
	tabKey: ProgressMapKeysEnum;
	projectId: number;
	shownTabs?: ProgressMapKeysEnum[];
	date?: string;
	onTabChange: (tabKey: string) => void;
	onClose: () => void;
}

export const ProcessTrackingModal = ({
	tabKey,
	projectId,
	shownTabs,
	date,
	onTabChange,
	onClose,
	...modalProps
}: ProcessTrackingModalProps) => {
	const { t } = useTranslation();

	const { progressKeys, progressMap } = useProgressMap();

	shownTabs = shownTabs ?? progressKeys;

	// const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
	// 	useContainerDetailModal();

	// const [selectedRecord, setSelectedRecord] = useSafeState<ProjectProgressContainer | null>();

	const {
		data: inTransitData,
		pagination: inTransitPaginition,
		loading: inTransitLoading,
		requestNextPage: inTransitRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.in_transit, projectId, date);

	const {
		data: arrivingData,
		pagination: arrivingPaginition,
		loading: arrivingLoading,
		requestNextPage: arrivingRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.arriving, projectId, date);

	const {
		data: avaliableData,
		pagination: avaliablePaginition,
		loading: avaliableLoading,
		requestNextPage: avaliableRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.avaliable, projectId, date);

	const {
		data: pickupData,
		pagination: pickupPaginition,
		loading: pickupLoading,
		requestNextPage: pickupRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.pickup, projectId, date);

	const {
		data: deliveredData,
		pagination: deliveredPaginition,
		loading: deliveredLoading,
		requestNextPage: deliveredRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.delivered, projectId, date);

	const {
		data: emptyReturnedData,
		pagination: emptyReturnedPaginition,
		loading: emptyReturnedLoading,
		requestNextPage: emptyReturnedRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.empty_returned, projectId, date);

	const {
		data: completedData,
		pagination: completedPaginition,
		loading: completedLoading,
		requestNextPage: completedRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.completed, projectId, date);

	const {
		data: canceledData,
		pagination: canceledPaginition,
		loading: canceledLoading,
		requestNextPage: cancledRequestNextPage,
	} = useProjectProgress(ProgressMapKeysEnum.canceled, projectId, date);

	const config = {
		[ProgressMapKeysEnum.in_transit]: {
			loading: inTransitLoading,
			data: inTransitData,
			pagination: inTransitPaginition,
			requestNextPage: inTransitRequestNextPage,
		},
		[ProgressMapKeysEnum.arriving]: {
			loading: arrivingLoading,
			data: arrivingData,
			pagination: arrivingPaginition,
			requestNextPage: arrivingRequestNextPage,
		},
		[ProgressMapKeysEnum.avaliable]: {
			loading: avaliableLoading,
			data: avaliableData,
			pagination: avaliablePaginition,
			requestNextPage: avaliableRequestNextPage,
		},
		[ProgressMapKeysEnum.pickup]: {
			loading: pickupLoading,
			data: pickupData,
			pagination: pickupPaginition,
			requestNextPage: pickupRequestNextPage,
		},
		[ProgressMapKeysEnum.delivered]: {
			loading: deliveredLoading,
			data: deliveredData,
			pagination: deliveredPaginition,
			requestNextPage: deliveredRequestNextPage,
		},
		[ProgressMapKeysEnum.empty_returned]: {
			loading: emptyReturnedLoading,
			data: emptyReturnedData,
			pagination: emptyReturnedPaginition,
			requestNextPage: emptyReturnedRequestNextPage,
		},
		[ProgressMapKeysEnum.completed]: {
			loading: completedLoading,
			data: completedData,
			pagination: completedPaginition,
			requestNextPage: completedRequestNextPage,
		},
		[ProgressMapKeysEnum.canceled]: {
			loading: canceledLoading,
			data: canceledData,
			pagination: canceledPaginition,
			requestNextPage: cancledRequestNextPage,
		},
	};

	// 每次页面切换，从头请求，
	const handleTabChange = async (key: string) => {
		try {
			onTabChange(key);
		} catch (error) {
			console.error(error);
		}
	};

	const items: TabsProps['items'] = shownTabs.map((key) => {
		const { title, textColor, Icon } = progressMap[key];

		return {
			key,
			label: (
				<div className='flex'>
					<Icon style={{ marginRight: 0 }} className='w-[18px] h-[18px]' />
					<Gap width='7px' />
					<span style={{ color: textColor }}>
						{title}
						{`(${config[key]?.pagination?.total})`}
					</span>
				</div>
			),
			children: (
				<ProgressTable
					progressKey={key}
					projectId={projectId}
					loading={config[key]?.loading ?? false}
					dataSource={config[key]?.data ?? []}
					onNextPage={config[key]?.requestNextPage}
				/>
				// <Spin spinning={config[key]?.loading ?? false}>
				// 	<Table
				// 		columns={columns}
				// 		dataSource={config[key]?.data ?? []}
				// 		className={`grow shrink overflow-scroll`}
				// 		commonCellStyle={{ borderBottom: '1px solid #e7e7e7' }}
				// 		commonCellClass={` box-border p-[0_10px] flex items-center box-border h-[44px]`}
				// 		commonHeaderClass={`foot-note-1-m text-[#777777]`}
				// 		commonBodyClass={`foot-note-1-r text-black whitespace-nowrap`}
				// 		onNextPage={config[key]?.requestNextPage}
				// 		onRowClick={(record) => {
				// 			setSelectedRecord(record);
				// 			openContainerDetailModal();
				// 		}}
				// 	/>
				// </Spin>
			),
		};
	});

	useMount(() => {
		shownTabs.map((key) => config[key].requestNextPage());
	});

	return (
		<Modal width={1248} footer={null} onCancel={() => onClose()} {...modalProps}>
			<Tabs
				activeKey={tabKey}
				items={items}
				className='w-full'
				onChange={(key) => handleTabChange(key)}
			/>
			{/* {openingContainerDetailModal && (
				<ContainerDetailModal
					mask={true}
					projectId={projectId}
					orderId={selectedRecord.order_id}
					containerId={selectedRecord.id}
					open={openingContainerDetailModal}
					onClose={() => {
						setSelectedRecord(null);
						closeContainerDetailModal();
					}}
				/>
			)} */}
		</Modal>
	);
};
