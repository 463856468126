import { Tabs, TabsProps } from 'antd';
import { Order } from '@/interfaces/order.interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import ContainersTable from './containers-table';
import DocumentsTable from './documents-table';
import InvoicesList from './invoices-list';
import MessagesList from './messages-list';
import OrderDetails from './order-details';
import RatesList from './rates-list';

interface ExpandedTabsProps extends TabsProps {
	order: Order;
}

export default observer(({ order, ...props }: ExpandedTabsProps) => {
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t('Containers'),
			children: <ContainersTable order={order} />,
		},
		{
			key: '2',
			label: t('Order Details'),
			children: <OrderDetails order={order} />,
		},
		// {
		// 	key: '3',
		// 	label: t('Rates'),
		// 	children: <RatesList order={order} />,
		// },
		{
			key: '4',
			label: t('Invoices'),
			children: <InvoicesList order={order} />,
		},
		{
			key: '5',
			label: t('Documents'),
			children: <DocumentsTable order={order} />,
		},
		{
			key: '6',
			label: t('Messages'),
			children: <MessagesList order={order} />,
		},
	];

	return <Tabs defaultActiveKey='1' {...props} items={items} />;
});
