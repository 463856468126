import { useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { requestMoreRate } from '@/services/apis';
import { t } from 'i18next';
import { CargoSelect } from '../components/search-rate-form/components/cargo-select';
import { CntrSizeGroup } from '../components/search-rate-form/components/cntr-size-group';
import { IntermodalRegionSelect } from '../components/search-rate-form/components/intermodal-region-select';
import { ToCitySelect } from '../components/search-rate-form/components/to-city-select';
import Input from '@/components/input';
import TextArea from '@/components/text-area';
import getCargoTypesQs from '@/pages/rate-request/components/search-form/get-cargo-types-qs';

const Item = Form.Item;

export default () => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const openModal = ({ intermodalRegionId, toCityId, toCity }) => {
		form.setFieldsValue({
			intermodal_region_id: intermodalRegionId,
			to_city_id: toCityId,
			to_city: toCity
				? {
						label: toCity.full_name || toCity.label,
						value: toCity.id || toCity.value,
				  }
				: {},
		});

		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
		form.resetFields();
	};

	const handleFinish = async (values: any) => {
		setLoading(true);
		delete values.toCity;
		try {
			await requestMoreRate({
				data: {
					...values,
					cargo_types: getCargoTypesQs(values.cargo_types ?? []),
				},
			});

			message.success('Sent Request');

			closeModal();
		} catch (error: any) {
			message.error(error?.message);
		} finally {
			setLoading(false);
		}
	};

	const RequestRateModal = () => {
		return (
			<Modal
				width={656}
				centered
				open={open}
				title={t(`Rate Unavailable: We're Here to Help`)}
				onCancel={closeModal}
				footer={null}
			>
				<p className='mb-[44px]'>
					{t(
						`Sorry, we can't offer you a rate for this route at the moment. Please submit a request and our team will get back to you as soon as possible!`,
					)}
				</p>

				<Form form={form} onFinish={handleFinish}>
					<div className='grid grid-cols-2 grid-rows-2 gap-[24px]'>
						<Form.Item noStyle name='to_city' hidden>
							<Input />
						</Form.Item>
						<Form.Item noStyle name='to_city_id'>
							{open && (
								<ToCitySelect
									disabled
									formItemKey='to_city_id'
									type='search'
									wrapperClass='p-[20px_16px] light-border rounded-[8px]'
									variant='borderless'
									disableContext
									fixedOptions={
										form.getFieldValue('to_city') ? [form.getFieldValue('to_city')] : []
									}
								/>
							)}
						</Form.Item>
						<Form.Item noStyle name='intermodal_region_id'>
							{open && (
								<IntermodalRegionSelect
									disabled
									type='search'
									formItemKey='intermodal_region_id'
									wrapperClass='p-[20px_16px] light-border rounded-[8px]'
									variant='borderless'
									disableContext
								/>
							)}
						</Form.Item>
						<Form.Item noStyle name='cargo_types'>
							<CargoSelect
								formItemKey='cargo_types'
								wrapperClass='p-[20px_16px] light-border rounded-[8px]'
								variant='borderless'
							/>
						</Form.Item>
						<Form.Item noStyle name='cntr_size' initialValue={40}>
							<CntrSizeGroup
								formItemKey='cntr_size'
								wrapperClass='p-[20px_16px] light-border rounded-[8px] h-full flex-center'
							/>
						</Form.Item>
					</div>
					<Item noStyle name='memo'>
						<TextArea
							className='mt-[24px] p-[20px_16px] border-[1px] border-solid rounded-[8px]'
							placeholder={t('Enter memo')}
						/>
					</Item>

					<Item className='mt-[24px] flex justify-end'>
						<Button
							disabled={loading}
							type='primary'
							htmlType='submit'
							className='w-[142px] h-[48px]'
						>
							{t('Request Rate')}
						</Button>
					</Item>
				</Form>
			</Modal>
		);
	};

	return {
		openModal,
		closeModal,
		RequestRateModal,
	};
};
