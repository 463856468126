import { useEffect } from 'react';
import { Button, Form, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Contact } from '@/interfaces/contact.interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { RequiredLabel } from '@/components/required-label';
import { Input } from '../../../table-input';

const Item = Form.Item;

const empty = {
	name: undefined,
	email: undefined,
	phone: undefined,
	remark: undefined,
};

export default observer(({ formItemKey }: { formItemKey: string }) => {
	const form = Form.useFormInstance();

	const validateEmail = (index, _, value, callback) => {
		const phoneValue = form.getFieldValue(index);

		// If phone is missing and email is also missing, show an error
		if (!phoneValue && !value) {
			callback('Email is required if Phone is missing');
		} else {
			callback();
		}
	};
	const contacts: Contact[] | undefined = form.getFieldValue(formItemKey);

	useEffect(() => {
		if (!contacts || contacts.length === 0) {
			addColumn();
		}
	}, [contacts]);

	const columns: ColumnsType<Contact> = [
		{
			title: <RequiredLabel label={t('Name')} />,
			dataIndex: 'name',
			key: 'name',
			render: (text, record, index) => (
				<Item noStyle name={[formItemKey, index, 'name']} rules={[{ required: true }]}>
					<Input />
				</Item>
			),
		},
		{
			// title: <RequiredLabel label={t('Email')} />,
			title: (
				<>
					<RequiredLabel label={t('Email')} />{' '}
					<small className='gray-5'>(Required if Phone is not present)</small>
				</>
			),
			dataIndex: 'email',
			key: 'email',
			render: (text, record, index) => (
				<Item
					noStyle
					name={[formItemKey, index, 'email']}
					rules={[
						{
							validator: (_, value, callback) =>
								validateEmail([formItemKey, index, 'phone'], _, value, callback),
						},
						{ type: 'email' },
					]}
				>
					<Input />
				</Item>
			),
		},
		{
			title: t('Phone'),
			dataIndex: 'phone',
			key: 'phone',
			render: (text, record, index) => (
				<Item noStyle name={[formItemKey, index, 'phone']}>
					<Input />
				</Item>
			),
		},
		{
			title: t('Remark'),
			dataIndex: 'remark',
			key: 'remark',
			render: (text, record, index) => (
				<Item noStyle name={[formItemKey, index, 'remark']}>
					<Input />
				</Item>
			),
		},
		{
			title: ' ',
			key: 'action',
			render: (text, record, index) => (
				<Item noStyle>
					<Button type='primary' ghost onClick={() => removeColumn(index)}>
						{t('Remove')}
					</Button>
				</Item>
			),
		},
	];

	const addColumn = () => {
		form.setFieldValue(formItemKey, [...(contacts ?? []), { ...empty }]);
	};

	const removeColumn = (index: number) => {
		const newContacts = contacts?.filter((c, _index) => _index !== index);
		form.setFieldValue(formItemKey, newContacts);
	};

	return (
		<>
			<Table
				className='custom-table'
				bordered={false}
				columns={columns}
				dataSource={contacts}
				pagination={false}
			/>
			<Button type='link' onClick={addColumn}>
				+ {t('Add Contact')}
			</Button>
		</>
	);
});
