import { Drawer, DrawerProps } from 'antd';
import { DraftOrder } from '@/interfaces/draft-order.interface';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import DraftOrderList from '../../components/draft-order-list';
import CenterSpin from '@/components/center-spin';

interface AllDraftOrderDrawerProps extends DrawerProps {
	draftOrders: DraftOrder[];
	fetching: boolean;
}

export default observer(({ draftOrders, fetching, ...props }: AllDraftOrderDrawerProps) => {
	return (
		<Drawer {...props} title={t('Draft Orders')}>
			{fetching ? (
				<CenterSpin size='large' />
			) : (
				<DraftOrderList draftOrders={draftOrders} max={6} />
			)}
		</Drawer>
	);
});
