import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { Container, Order } from '@/interfaces/order.interface';
import clsx from 'clsx';
import { t } from 'i18next';
import ContainerStatusLabel from '@/components/container-status-label';
import useFilter from './use-filter';

const { Sider } = Layout;

interface OrderSiderProps {
	order: Order;
	selectedContainerId: Container['id'];
}

interface ContainerItemProps {
	container: Container;
	isSelected: boolean;
	onClick: (container: Container) => void;
}

const ContainerItem = ({ container, isSelected, onClick }: ContainerItemProps) => (
	<div
		className={clsx(
			'p-[20px_24px_20px_24px]',
			'cursor-pointer',
			isSelected &&
				'bg-updated-light-blue-02 border-t-0 border-l-0 border-r-[5px] border-solid border-primary-regular',
		)}
		style={{ borderBottom: '1px solid #E6F0FA' }}
		onClick={() => onClick(container)}
	>
		{/* <div className='flex'> */}
		{/* @ts-ignore */}
		<div className='body-3-b'>{container.number}</div>
		<ContainerStatusLabel isSelected={isSelected} status={container.status_text} />
		{/* </div> */}
		{/* <div></div> */}
	</div>
);

export default ({ order, selectedContainerId }: OrderSiderProps) => {
	const navigate = useNavigate();

	const handleClick = (container: Container) => {
		navigate(`/orders/${order.id}/containers/${container.id}`);
	};

	const { selectedKeys, Filter } = useFilter();

	return (
		<Sider
			width={292}
			className=' h-full '
			style={{ backgroundColor: '#ffffff', borderRight: '1px solid #E6F0FA' }}
		>
			<div className='h-full flex flex-col'>
				<div
					className='p-[12px_12px_12px_28px] flex justify-between items-center'
					style={{ borderBottom: '1px solid #E6F0FA' }}
				>
					<span>
						{order.containers.length} {t('total containers')}
					</span>
					{/* <Filter containers={order.containers} /> */}
				</div>

				<div style={{ overflowY: 'auto', height: 'calc(100% - 57px)' }}>
					{order.containers
						// .filter((container) => selectedKeys.includes(container.status))
						.map((container) => (
							<ContainerItem
								container={container}
								isSelected={selectedContainerId === container.id}
								onClick={handleClick}
							/>
						))}
				</div>
			</div>
		</Sider>
	);
};
