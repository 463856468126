import { Form, Tooltip } from 'antd';
import DropIcon from '@/icons/drop-icon';
import InfoIcon from '@/icons/info-icon';
import LiveIcon from '@/icons/live-icon';
import { LiveOrDrop } from '@/interfaces/live-or-drop.enum';
import { useGlobalContext } from '@/services/contexts';
import clsx from 'clsx';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';

export const DeliveryTypeSelectLabel = () => {
	const toolTip = (
		<div>
			<div>
				{t(
					'Live unloading refers to unloading goods from a container at the warehouse, with the driver waiting on-site. After the unloading is completed, the empty container is directly taken away.',
				)}
			</div>
			<div>
				{t(
					'Drop unloading is another common warehouse unloading method, as opposed to live unload. The container is unloaded at the warehouse and the driver does not wait on site. They drop off the container and leave, returning when the warehouse notifies them that the empty container can be picked up.',
				)}
			</div>
		</div>
	);

	return (
		<>
			{t('Delivery Type')}
			<Tooltip title={toolTip} overlayInnerStyle={{ width: '500px' }}>
				<InfoIcon className='ml-[5px]' />
			</Tooltip>
		</>
	);
};

export default observer(({ formItemKey }: { formItemKey: string }) => {
	const form = Form.useFormInstance();
	const { userContext } = useGlobalContext();

	const selectedValue: LiveOrDrop = form.getFieldValue(formItemKey);

	const setValue = (value: LiveOrDrop) => {
		if (selectedValue === value) {
			form.setFieldValue(formItemKey, LiveOrDrop.Empty);
		} else {
			form.setFieldValue(formItemKey, value);
		}
	};

	const getClassName = (value: LiveOrDrop) => {
		const isSelected = selectedValue === value;
		const selectedClass = isSelected
			? 'border-[3px] border-[#007BFF] bg-[#EBF3FF]'
			: 'light-border border-[2px] bg-[#FFFFFF]';
		return clsx(selectedClass);
	};
	return (
		<div>
			<div className='flex w-full'>
				<div className='flex-grow flex-1'>
					<div
						className={clsx(
							'box-content flex items-center pl-[18px] h-[50px] border-solid rounded-[6px]',
							getClassName(LiveOrDrop.Live),
							'mr-[14px]',
						)}
						onClick={() => setValue(LiveOrDrop.Live)}
					>
						<LiveIcon className='mr-[12px]' />
						{t('Live')}
					</div>
				</div>

				<div className='flex-grow flex-1'>
					<div
						className={clsx(
							'box-content  flex items-center pl-[18px] h-[50px] border-solid rounded-[6px]',
							getClassName(LiveOrDrop.Drop),
						)}
						onClick={() => setValue(LiveOrDrop.Drop)}
					>
						<DropIcon className='mr-[12px]' />
						{t('Drop')}
					</div>
					{!userContext.getUserInfo()?.__enable_drop_feature && (
						<div className='text-updated-red'>
							<small>{t('Please contact sales to confirm the Drop Fee.')}</small>
						</div>
					)}
				</div>
			</div>
		</div>
	);
});
