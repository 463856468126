import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { Invoice } from '@/interfaces/invoice.interface';
import { t } from 'i18next';
import { BillContent } from '@/pages/order-detail/order-detail-container/components/billing/bill-content';

export default ({ invoice }: { invoice: Invoice }) => {
	const [open, setOpen] = useState(false);

	const openReviewModal = () => {
		setOpen(true);
	};

	const closeReviewModal = () => setOpen(false);

	const ReviewModal = () => (
		<Modal
			open={open}
			width={700}
			centered={true}
			footer={<Button onClick={closeReviewModal}>{t('Close')}</Button>}
			title={t('Review')}
			onCancel={closeReviewModal}
		>
			{invoice && <BillContent invoice={invoice} />}
		</Modal>
	);

	return {
		openReviewModal,
		closeReviewModal,
		ReviewModal,
	};
};
