import { useCallback, useState } from 'react';
import { Modal, Timeline, TimelineProps } from 'antd';
import { Container } from '@/interfaces/order.interface';
import { t } from 'i18next';
import { ArrElement } from '@/utils/array-element.type';

const TimelineItem = ({ event }: { event: ArrElement<Container['shipping_events']> }) => (
	<div>
		<div className='flex justify-between'>
			<div className='font-[500] text-[14]'>{event.activity}</div>
			<div className='body-4-m text-grey-01'>{event.time}</div>
		</div>
		<div className='font-[400] text-[12px] text-grey-01 leading-[20px]'>{event.location}</div>
	</div>
);

export default () => {
	const [timelineItems, setTimelineItems] = useState<TimelineProps['items']>([]);

	const [open, setOpen] = useState(false);

	const openTimelineModal = useCallback((shippingEvents: Container['shipping_events']) => {
		const items: TimelineProps['items'] = shippingEvents
			? shippingEvents.map((e) => ({
					children: <TimelineItem event={e} />,
			  }))
			: [];
		setTimelineItems(items);
		setOpen(true);
	}, []);

	const closeTimelineModal = useCallback(() => {
		setOpen(false);
		setTimelineItems([]);
	}, []);

	const TimelineModal = () => (
		<Modal
			title={t('Shipment History')}
			open={open}
			footer={null}
			width={748}
			styles={{
				body: { padding: '10px', height: '560px', overflowY: 'scroll' },
			}}
			onCancel={closeTimelineModal}
		>
			<Timeline items={timelineItems} />
		</Modal>
	);

	return {
		openTimelineModal,
		closeTimelineModal,
		TimelineModal,
	};
};
