import { Button } from 'antd';
import InvoiceIcon from '@/icons/invoice-icon';
import { Order } from '@/interfaces/order.interface';
import { downloadInvoice } from '@/services/apis';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ArrElement } from '@/utils/array-element.type';
import useReviewInvoice from './review-invoice';

interface InvoicesListProps {
	order: Order;
}

interface InvoiceItemRenderProps {
	invoice: ArrElement<Order['invoices']>;
}

const InvoiceItemRender = observer(({ invoice }: InvoiceItemRenderProps) => {
	const { id, container_numbers, due_at, amount_total } = invoice;
	const { openReviewModal, ReviewModal } = useReviewInvoice({
		invoice,
	});

	return (
		<>
			{container_numbers.map((container) => (
				<div className=' px-[8px] py-[6px] flex justify-between items-center bg-[#ffffff]'>
					{/* left */}
					<div className='flex'>
						{/* icon */}
						<InvoiceIcon className='mr-[10px]' />
						<div>
							<div className='mb-[2px] body-4-b'>{container}</div>
							<div className='foot-note-1-m text-[#97999C]'>{due_at}</div>
						</div>
					</div>

					{/* middle */}
					<div className='body-4-b'>${amount_total}</div>

					<span className='text-[#367cf2] cursor-pointer' onClick={openReviewModal}>
						{t('Review')}
					</span>
				</div>
			))}
			<ReviewModal />
		</>
	);
});

export default observer(({ order }: InvoicesListProps) => {
	return (
		<div className='grid grid-cols-2 gap-x-[50px] gap-y-[8px]'>
			{/* {order.invoices.map((invoice) => (
				<InvoiceItemRender invoice={invoice} />
			))} */}
			{order.invoices.map((invoice) => (
				<InvoiceItemRender invoice={invoice} />
			))}
		</div>
	);
});
