import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'qs';
import valueTransfer from '@/utils/value-transfer';
import { OrderQuery } from './type';

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'query', searchParams.query, String);
		valueTransfer(res, 'created_at_start_date', searchParams.created_at_start_date, dayjs);
		valueTransfer(res, 'created_at_end_date', searchParams.created_at_end_date, dayjs);
		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));
		valueTransfer(res, 'defaultOrderId', searchParams.defaultOrderId, (v) =>
			v ? Number(v) : undefined,
		);

		return res as Partial<OrderQuery>;
	}, [searchParams]);

	const changeSearchParams = useCallback((values: any) => {
		setSearchParams(qs.stringify({ ...values }));
	}, []);

	const changeDefaultOrderId = useCallback(
		(orderId?: number) => {
			const searchParams = parsedSearchParams;

			if (orderId) {
				searchParams.defaultOrderId = orderId;
			} else {
				delete searchParams['defaultOrderId'];
			}
			const newUrl = `${window.location.origin}${window.location.pathname}?${qs.stringify(
				searchParams,
			)}`;
			window.history.replaceState({}, '', newUrl);
		},
		[parsedSearchParams],
	);

	const scrollToDefaultOrderIdAndExpand = useCallback(() => {
		const { defaultOrderId } = parsedSearchParams;

		if (!defaultOrderId) return;

		const element = document.querySelector(`[data-row-key="${defaultOrderId}"]`);

		if (!element) return;

		// expand button
		const expandEle = element.querySelector(
			`.ant-table-row-expand-icon-cell span`,
		) as HTMLSpanElement;

		if (!expandEle) return;

		expandEle.click();

		setTimeout(() => {
			// expand row
			const expandRow = document.querySelector(`.ant-table-expanded-row`);

			// scroll
			expandRow?.scrollIntoView?.({ behavior: 'smooth' });
		}, 1000);
	}, [parsedSearchParams]);

	const cleanDefaultOrderId = useCallback(() => {
		changeDefaultOrderId();
	}, [changeDefaultOrderId]);

	return {
		parsedSearchParams,
		changeSearchParams,
		changeDefaultOrderId,
		scrollToDefaultOrderId: scrollToDefaultOrderIdAndExpand,
		cleanDefaultOrderId,
	};
};
