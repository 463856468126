import { Select as AntdSelect, Checkbox, Form, Tag } from 'antd';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Select from '@/components/select';
import StatusLabel from '../../../status-label';
import { CargoTypeSelectProps } from './type';

const options = [
	{
		label: <StatusLabel statusKey='completed' />,
		value: 'completed',
	},
	{
		label: <StatusLabel statusKey='in_progress' />,
		value: 'in_progress',
	},
	{
		label: <StatusLabel statusKey='rejected' />,
		value: 'rejected',
	},
];

export default observer(function ({ formItemKey, className, ...props }: CargoTypeSelectProps) {
	const form = Form.useFormInstance();

	const isSelected = (v: string) => (form.getFieldValue(formItemKey) ?? []).includes(v);

	/**
	 * 标签渲染
	 * @param props
	 * @returns
	 */
	const TagRender = (props: any) => {
		const { value, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		const originLabel = options.find((o) => o.value === value)?.label;
		return (
			<Tag
				className='flex pl-[11px] w-fit h-[30px] border-[1px] border-solid border-[#DBEDFF] bg-[#F5FAFF] text-[#3A92F7]   text-center  rounded-[200px] font-[400] text-[14px] '
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={(e) => {
					onClose(e);
				}}
				style={{ marginRight: 3 }}
			>
				{originLabel}
			</Tag>
		);
	};

	return (
		<Select
			{...props}
			// open={true}
			mode='multiple'
			tagRender={(tagProps) => <TagRender {...tagProps} />}
			className={clsx(className)}
			maxTagCount='responsive'
		>
			{options.map(({ value, label }) => (
				<AntdSelect.Option key={value} value={value} label={label}>
					<div className='flex items-center'>
						<Checkbox className='mr-[12px]' checked={isSelected(value)} />
						{label}
					</div>
				</AntdSelect.Option>
			))}
		</Select>
	);
});
