import { useMemo } from 'react';
import { Col, Form, Row, Tooltip } from 'antd';
import ShippingContainerIcon from '@/icons/shipping-container-icon';
import { ValidErrorField } from '@/interfaces/valid-error-field.interface';
import { InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import ContactEmails from './components/contact-emails';
import PickupNumberAgentSelect from './components/pickup-number-agent-select';
import UploadFileDragger from './components/upload-file-dragger';
import Input from '@/components/input';
import useValidError from '../../hooks/use-valid-error';
import { isRampPort } from '@/utils/auto-determine-destination-port';
import BottomButtons from '../bottom-buttons';
import { InputTextArea } from '../input';
import Panel from '../panel';
import { Title } from '../title';
import './index.less';
import { CarrierDetailProps } from './type';

const Item = Form.Item;

export const CarrierDetails = observer(
	({ NextButton, BackButton, SaveDraftButton }: CarrierDetailProps) => {
		const form = Form.useFormInstance();

		const { addValidError, clearValidErrors, getOneValidError } = useValidError();

		const portOfDischargeId = Form.useWatch('port_of_discharge_id');

		const finalPortId = Form.useWatch('final_port_id');

		const isRamp = useMemo(
			() => isRampPort(portOfDischargeId, finalPortId),
			[portOfDischargeId, finalPortId],
		);

		/**
		 * 手动触发字段校验
		 * @returns
		 */
		const validateFields = async () => {
			try {
				const nameLists = ['mbl_number'];
				await form.validateFields(nameLists);
			} catch (error: any) {
				console.error('has error', error);
				if (!error.errorFields?.length || error.errorFields.length === 0) {
					clearValidErrors();
					return;
				}
				error.errorFields.forEach((field: ValidErrorField) => {
					const key = field.name.join('_');
					addValidError(key, field);
				});
				throw error;
			}
		};

		const footer = (
			<BottomButtons
				leftButton={<BackButton />}
				rightButton1={<SaveDraftButton />}
				rightButton2={<NextButton onClick={validateFields} />}
				rightButton2Message={
					<div className='h-[22px] text-[#FF0000] text-[14px] font-[400]'>
						{getOneValidError() ? t('Please complete the highlighted fields') : ` `}
					</div>
				}
			/>
		);

		return (
			<Panel footer={footer}>
				<div className='h-full '>
					<Title title={t('Book Details')} Icon={ShippingContainerIcon} />
					<Row gutter={60} style={{ height: 'calc(100% - 62px)' }} className='h-fit max-h-[90vh]'>
						<Col span={12}>
							<Item
								name='mbl_number'
								label={
									<span>
										{t('Steam Shipline MBL')}
										{/* <span className='ml-[4px] text-[#9D9D9D] underline'>
											{t('How to find the information?')}
										</span> */}
									</span>
								}
								rules={[{ required: true, message: t('Please enter Steam Shipline MBL') }]}
							>
								<Input />
							</Item>
							<Item name='customer_reference_number' label={t('Customer Reference')}>
								<Input />
							</Item>
							{/* <Item name='commodity' label={t('Commodity')}>
								<Input />
							</Item> */}
							<Item name='customer_memo' label={t('Delivery Instructions')}>
								<InputTextArea rows={2} />
							</Item>

							<div className='pb-[6px]'>
								{t('Relevant File (optional)')}{' '}
								<Tooltip title={t('PS_MBL_HBL_DO')}>
									<InfoCircleOutlined />
								</Tooltip>
							</div>
							<Item noStyle name='files'>
								<UploadFileDragger className='book-upload-wrapper' />
							</Item>
						</Col>
						<Col span={12} className='h-full'>
							<Item noStyle name='contact_emails'>
								<ContactEmails />
							</Item>
							{isRamp && (
								<Item noStyle name='pickup_number_agent'>
									<PickupNumberAgentSelect
										formItemKey='pickup_number_agent'
										wrapperClass='h-full flex flex-col'
									/>
								</Item>
							)}
						</Col>
					</Row>
				</div>
			</Panel>
		);
	},
);
