import { useTranslation } from 'react-i18next';
import { Affix, Col, Row } from 'antd';
import AddToCurrentProjectIcon from '@/icons/add-to-current-project-icon';
import CreateNewProjectIcon from '@/icons/create-new-project-icon';
import { Order } from '@/interfaces/order.interface';
import { AddProjectModal } from '@/components/add-project';
import { useAddProjectModal } from '@/components/add-project/use-add-project-modal';
import { CreateProjectPanel, useCreateProjectModal } from '@/components/create-project-panel';

interface RowSelectToolbarProps {
	selectedRowKeys: Order['id'][];
	clearSelectedRowKeys: () => void;
}

export const RowSelectToolbar = ({
	selectedRowKeys,
	clearSelectedRowKeys,
}: RowSelectToolbarProps) => {
	const nums = selectedRowKeys.length;

	const allowShow = nums > 0;

	const { t } = useTranslation();

	const { openingAddProjectModal, openProjectModal, closeProjectModal } = useAddProjectModal();

	const { openingCreateProjectModal, openCreateProjectModal, closeCreateProjectModal } =
		useCreateProjectModal();

	console.log('selectedRowKeys', selectedRowKeys);

	return (
		allowShow && (
			<>
				<Affix className='w-full' style={{ position: 'absolute', bottom: '18px', margin: 'auto' }}>
					<div className='pl-[20px] pr-[42px] w-full h-[69px] flex justify-between items-center rounded-[16px] bg-primary-regular'>
						<div className='h4-m text-white'>{t(`orders selected`, { nums })}</div>

						<Row gutter={24}>
							<Col>
								<div onClick={openCreateProjectModal}>
									<CreateNewProjectIcon className='cursor-pointer' />
								</div>
							</Col>
							<Col>
								<div onClick={openProjectModal}>
									<AddToCurrentProjectIcon className='cursor-pointer' />
								</div>
							</Col>
							<Col>
								<div
									className='h-full flex items-center h4-m text-white cursor-pointer'
									onClick={clearSelectedRowKeys}
								>
									{t(`Cancel`)}
								</div>
							</Col>
						</Row>
					</div>
				</Affix>
				<AddProjectModal
					modalOptions={{
						open: openingAddProjectModal,
					}}
					orderIds={selectedRowKeys}
					onClose={closeProjectModal}
				/>
				{openingCreateProjectModal && (
					<CreateProjectPanel
						type='batch-create'
						open={openingCreateProjectModal}
						onClose={closeCreateProjectModal}
						defaultValue={{
							orderId: selectedRowKeys,
						}}
					/>
				)}
			</>
		)
	);
};
