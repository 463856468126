import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownProps, MenuProps } from 'antd';
import { Container, Order } from '@/interfaces/order.interface';
import { isOrderExpired } from '@/services/apis';
import {
	ClockCircleOutlined,
	CloseCircleOutlined,
	DiffOutlined,
	DownOutlined,
	EditOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import { t } from 'i18next';
import useExpiredModal from './use-expired-modal';
import useHoldDelivery from './use-hold-delivery';

interface ModifyDropdownProps {
	order: Order;
	isText: boolean;
}

export default ({ order, isText }: ModifyDropdownProps) => {
	// const { openHoldDeliveryModal, HoldDeliveryModal } = useHoldDelivery();

	const navigate = useNavigate();

	const { openModal, ExpiredModal } = useExpiredModal();

	const modifyItems: MenuProps['items'] = [
		// {
		// 	key: t('Update_Address'),
		// 	label: t('Update Address'),
		// 	icon: <EditOutlined rev color='#007BFF' className=' text-primary-regular' />,
		// },
		// {
		// 	key: t('Hold_Delivery'),
		// 	label: t('Hold Delivery'),
		// 	icon: <ClockCircleOutlined rev className=' text-primary-regular' />,
		// },
		// {
		// 	key: t('Cancel_Order'),
		// 	label: t('Cancel Order'),
		// 	icon: <CloseCircleOutlined rev className=' text-updated-red' />,
		// },
		{
			key: t('Duplicate Order'),
			label: t('Duplicate Order'),
			icon: <DiffOutlined className=' text-primary-regular' />,
			onClick: async ({ domEvent }) => {
				domEvent.stopPropagation();
				const res = await isOrderExpired({ path: { orderId: order.id } });
				const is_expired = res.data.is_expired;
				if (!is_expired) {
					const navUrl = `/search-rate?order_id=${order.id}&to_city_id=${order.warehouse.city.id}&intermodal_region_id=${order.final_port.id}`;
					navigate(navUrl);
				} else {
					openModal(order);
				}
			},
		},
	];

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
		>
			<Dropdown menu={{ items: modifyItems }}>
				{isText ? (
					<Button>
						{t('Modify')}
						<DownOutlined />
					</Button>
				) : (
					<Button type='text'>
						<MoreOutlined />
					</Button>
				)}
			</Dropdown>
			{/* <HoldDeliveryModal /> */}
			<ExpiredModal />
		</div>
	);
};
