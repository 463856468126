import { useState } from 'react';
import { SelectProps } from 'antd';
import { City } from '@/interfaces/city.interface';
import { searchCity } from '@/services/apis';
import { useDebounceFn } from 'ahooks';
import clsx from 'clsx';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import CenterSpin from '@/components/center-spin';
import Select from '@/components/select';

export default observer(({ className, ...props }: SelectProps) => {
	const [fetching, setFetching] = useState(false);

	const [toCities, setToCities] = useState<City[]>([]);

	const options = toCities.map((o) => ({
		label: o.full_name,
		value: o.id,
	}));

	/**
	 * 搜索匹配的港口
	 * @param searchText
	 */
	const { run: handleSearch } = useDebounceFn(
		async (searchText: string) => {
			let fetchIndex = 0;

			const _handleSearch = async (searchText: string) => {
				if (searchText === '') return;
				setFetching(true);
				fetchIndex += 1;
				const _fetchIndex = fetchIndex;
				const res = await searchCity({ path: { cityName: searchText } });
				if (_fetchIndex !== fetchIndex) return;
				setToCities([...res.data.data]);
				setFetching(false);
			};

			return _handleSearch(searchText);
		},
		{ wait: 500 },
	);

	return (
		<Select
			{...props}
			filterOption={false}
			className={clsx(className)}
			showSearch
			allowClear
			notFoundContent={fetching ? <CenterSpin size='small' /> : null}
			onSearch={handleSearch}
			options={options}
		/>
	);
});
