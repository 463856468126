import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { billingCodeNameTranslateKey } from '@/utils/billing-code-translate';

interface RenderPossibleChargeCodeOrNameProps {
	nameOrCode: string;
}

export default observer(({ nameOrCode }: RenderPossibleChargeCodeOrNameProps) => {
	return <>{t(billingCodeNameTranslateKey(nameOrCode))}</>;
});
