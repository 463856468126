import { useEffect } from 'react';
import { Button, Form, FormProps } from 'antd';
import TrashIcon from '@/icons/trash-icon';
import { useRequestedRateContext } from '@/services/contexts';
import { useDebounceFn } from 'ahooks';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import CargoTypeSelect from './components/cargo-type-select';
import IntermodalRegionSelect from './components/intermodal-region-select';
import StatusSelect from './components/status-select';
import ToCitySelect from './components/to-city-select';
import getCargoTypesQs from './get-cargo-types-qs';
import './index.less';
import useRequestedRatesParams from './use-requested-rates-params';

const Item = Form.Item;

export default observer(function () {
	const [form] = Form.useForm();

	const requestedRateContext = useRequestedRateContext();

	const { parsedSearchParams, changeSearchParams } = useRequestedRatesParams();

	const { run: handleValueChange } = useDebounceFn(
		(_, values) => {
			values = {
				...parsedSearchParams,
				...values,
				page: 1,
			};

			changeSearchParams(values);

			requestedRateContext.fetchRequestedRatesAndAutoSet({
				params: {
					...values,
					cargo_types: getCargoTypesQs(values.cargo_types ?? []),
				},
			});
		},
		{ wait: 500 },
	);

	useEffect(() => {
		handleValueChange(_, parsedSearchParams);
	}, []);

	return (
		<Form
			form={form}
			initialValues={parsedSearchParams}
			layout='vertical'
			className='flex justify-between items-end ml-[30px] mr-[62px]'
			onValuesChange={handleValueChange}
		>
			<div className='flex '>
				<div className='mr-[16px] w-[200px]'>
					<Item name='intermodal_region_id' label={t('Port') + '/' + t('Ramp')}>
						<IntermodalRegionSelect className='rate-request-form-select' />
					</Item>
				</div>

				<div className='mr-[16px] w-[200px]'>
					<Item name='to_city_id' label={t('Destination')}>
						<ToCitySelect className='rate-request-form-select' />
					</Item>
				</div>

				<div className='mr-[16px] w-[340px]'>
					<Item name='state' label={t('Status')}>
						<StatusSelect formItemKey='state' className='rate-request-form-select' />
					</Item>
				</div>

				<div className='w-[295px]'>
					<Item name='cargo_types' label={t('Cargo type')}>
						<CargoTypeSelect formItemKey='cargo_types' className='rate-request-form-select' />
					</Item>
				</div>
			</div>
		</Form>
	);
});
