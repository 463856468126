import { useState } from 'react';
import { Segmented } from 'antd';
import { ContainerDetail } from '@/interfaces/container-detail.interface';
import { Container, Order } from '@/interfaces/order.interface';
import { t } from 'i18next';
import Billing from './components/billing';
import Documents from './components/documents';
import Rate from './components/rate';
import Tracking from './components/tracking';
import { TransactionLog } from './components/transaction';

interface OrderDetailContainerProps {
	order: Order;
	container: ContainerDetail;
	fetchData: () => void;
}

export default ({ order, container, fetchData }: OrderDetailContainerProps) => {
	const {
		number,
		seal_number,
		port_of_loading_etd,
		port_of_discharge_eta,
		actual_terminal_pickuped_at,
		actual_delivered_at,
		actual_empty_returned_at,
		freight_released_at,
		customs_released_at,
		terminal_released_at,
		ready_to_pickup_at,
		shipping_events,
	} = container;

	const items = {
		[t('Tracking')]: <Tracking order={order} container={container} />,
		[t('Invoice')]: <Billing container={container} />,
		[t('Documents')]: <Documents order={order} fetchData={fetchData} />,
		[t('Rate')]: <Rate container={container} />,
		[t('Transaction')]: <TransactionLog order_id={order.id} container_id={container.id} />,
	};

	const [selectedTab, setSelectedTab] = useState<string>(t('Tracking'));

	const selectTab = (tab: string) => setSelectedTab(tab);

	return (
		<div className='h-full overflow-scroll rounded-[8px] shadow=[0px_6px_18px_0px_#8F95B240]'>
			<Segmented
				className='mb-[16px]'
				options={[t('Tracking'), t('Invoice'), t('Documents'), t('Rate'), t('Transaction')]}
				defaultValue={t('Tracking')}
				onChange={(value) => selectTab(String(value))}
			/>
			{items[selectedTab]}
		</div>
	);
};
