import { useParams } from 'react-router-dom';
import { Space, Tooltip } from 'antd';
import { Pie, PieConfig } from '@ant-design/charts';
import { InfoCircleOutlined } from '@ant-design/icons';
import CenterSpin from '@/components/center-spin';
import { Gap } from '@/components/gap';
import {
	ProcessTrackingModal,
	useProcessTrackingModal,
} from '@/pages/project/components/progress-tracking-modal';
import { ProgressMapKeysEnum, useProgressMap } from '@/pages/project/use-progress-map';
import { useGetProjectsProgressStatistics } from '@/hooks/apis/use-get-projects-progress-statistics';

export interface ProgressDataItem {
	containerId: string;
	progress: string;
	mbl: string;
	ir: string;
	destination: string;
	eta: string;
	lfd: string;
	est_pickup: string;
	actual_pickup: string;
}

export const ProgressPanel = () => {
	// const { projectId } = useParams();

	const { data, loading } = useGetProjectsProgressStatistics();

	const {
		tabKey,
		openingProcessTrackingModal,
		handleTabChange,
		openProcessTrackingModal,
		closeProcessTrackingModal,
	} = useProcessTrackingModal();

	const { progressStatisticKeys, progressStatisticMap, progressMap } = useProgressMap();

	const renderData = progressStatisticKeys.map((k) => ({ type: k, value: data?.[k] ?? 0 }));

	const config: PieConfig = {
		data: renderData,
		angleField: 'value',
		colorField: 'type',
		color: Object.values(progressMap).map((i) => i.textColor),
		legend: false,
		label: false,
		tooltip: {
			formatter: (datum) => {
				const type = datum.type as ProgressMapKeysEnum;
				return {
					name: progressStatisticMap[type].title,
					value: datum.value,
				};
			},
		},
		innerRadius: 0.9,
		autoFit: true,
		style: {
			stroke: '#fff',
			inset: 1,
		},
		startAngle: Math.PI * 0.75,
		endAngle: Math.PI * 0.25,
		statistic: {
			title: {
				offsetY: -100,
				customHtml: (container, view, datum, data) => {
					return `<div></div>`;
				},
			},
			content: {
				offsetY: -14,
				style: {
					fontSize: '32px',
				},
				customHtml: (container, view, datum, data) => {
					const totalValue = data.reduce((r, d) => r + d.value, 0);

					return `
					<div>
						<div style="margin-bottom:4px;font-size:32px;font-weight:600;">${totalValue}</div>
						<div style="font-size:16px;font-weight:600;color:#747578">Total</div>
					</div>
					`;
				},
			},
		},
	};

	if (loading) return <CenterSpin />;

	return (
		<>
			<div className='h-[40vh] w-full flex flex-col overflow-hidden'>
				<div className='grow shrink overflow-hidden'>
					<Pie className='chart-container w-full h-full overflow-hidden' {...config} />
				</div>
				<Gap height='16px' />
				<Space direction='vertical' size={4} className='w-full h-fit grow-0 shrink-0'>
					{progressStatisticKeys.map((key) => {
						const Icon = progressStatisticMap[key].Icon;
						return (
							<Tooltip placement='topLeft' title={progressStatisticMap[key].title}>
								<div
									key={key}
									className='flex justify-between items-center cursor-pointer'
									onClick={() => openProcessTrackingModal(key.slice(0, -6) as ProgressMapKeysEnum)}
								>
									{/* left */}
									<div className='flex items-center'>
										{/* icon */}
										<div className='mr-[8px]'>
											<Icon className='w-[18px]' />
										</div>
										{/* process */}
										<div className='body-4-r text-black'>
											{progressStatisticMap[key].title} {''}
										</div>
									</div>

									{/* right */}
									<div className='body-3-b text-black'>{data?.[key] ?? 0}</div>
								</div>
							</Tooltip>
						);
					})}
				</Space>
			</div>
			{openingProcessTrackingModal && (
				<ProcessTrackingModal
					tabKey={tabKey}
					projectId={0}
					open={openingProcessTrackingModal}
					onTabChange={handleTabChange}
					onClose={closeProcessTrackingModal}
				/>
			)}
		</>
	);
};
