import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin } from 'antd';
import { ProjectProgressContainer } from '@/services/apis';
import { downloadFile } from '@/services/apis/download-file';
import { WarningOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { useSafeState } from 'ahooks';
import { ContainerDetailModal, useContainerDetailModal } from '@/components/container-detail-modal';
import CustomReleased from '@/components/container-release-status-icon/custom-released';
import SslReleased from '@/components/container-release-status-icon/ssl-released';
import { Gap } from '@/components/gap';
import { Table, TableProps } from '@/components/table';
import Risks from '@/pages/order/components/Risks';
import { ProgressLabel, ProgressMapKeysEnum } from '@/pages/project/use-progress-map';
import { ExceptionTag } from '../project-exceptions';

interface ProgressTableProps {
	loading: boolean;
	dataSource: ProjectProgressContainer[];
	progressKey: ProgressMapKeysEnum;
	projectId: number;
	alwaysShowPodAndEir?: boolean;
	onNextPage: () => void;
}

export const ProgressTable = ({
	loading,
	dataSource,
	progressKey,
	projectId,
	alwaysShowPodAndEir = false,
	onNextPage,
}: ProgressTableProps) => {
	const { t } = useTranslation();

	const { openingContainerDetailModal, openContainerDetailModal, closeContainerDetailModal } =
		useContainerDetailModal();

	const [selectedRecord, setSelectedRecord] = useSafeState<ProjectProgressContainer | null>();

	const columns: TableProps<ProjectProgressContainer>['columns'] = useMemo(() => {
		const baseColumns = [
			{
				title: t('Container #'),
				dataIndex: 'number',
				CellRender({ style, className, value, record, onRowClick }) {
					const troubleStatus = record.trouble_status ?? [];
					return (
						<>
							<div
								style={{ ...(style ?? {}) }}
								className={`${className} flex items-center`}
								onClick={() => onRowClick(record)}
							>
								<div>{value}</div>
								<Gap width='4px' />
								{record.has_demurrage_expcetion && <ExceptionTag reason='demurrage' />}
								{troubleStatus.length > 0 && (
									<div className='flex items-center text-additional-warning'>
										<Gap width='12px' />
										<WarningOutlined />
										<span className='mt-1'>{troubleStatus.length}</span>
									</div>
								)}{' '}
								{/* {troubleStatus.length > 0 && (
									<ProjectExceptionWarning exceptions={troubleStatus} />
								)} */}
								{record.risks && (
									<div className='items-center ml-1'>
										<Risks risks={record.risks} />
									</div>
								)}
							</div>
						</>
					);
				},
			},
			{
				title: t('Progress'),
				dataIndex: 'progress',
				CellRender({ style, className, record, onRowClick }) {
					return (
						<div
							style={{ ...(style ?? {}) }}
							className={`${className}`}
							onClick={() => onRowClick(record)}
						>
							<ProgressLabel type={record.progress_type} />
						</div>
					);
				},
			},
			{
				title: t('MBL'),
				dataIndex: ['order', 'mbl_number'],
			},
			{
				title: t('IR'),
				dataIndex: ['port_of_discharge', 'name'],
			},
			{
				title: t('Destination'),
				dataIndex: ['warehouse', 'city', 'full_name'],
			},
			{
				title: t('ETA'),
				dataIndex: ['final_port_eta'],
			},
			{
				title: t('LFD'),
				dataIndex: 'lfd',
			},
		];

		const podColoumn = {
			title: t('POD'),
			dataIndex: 'pod',
			CellRender({ style, className, record, onRowClick }) {
				return (
					<div style={{ ...(style ?? {}) }} className={`${className}`}>
						{record.pod && (
							<Button
								type='text'
								icon={<DownloadOutlined />}
								onClick={() =>
									downloadFile(
										{
											path: {
												documentId: record.pod.id,
												orderId: record.order_id,
											},
											other: {
												fileName: record.pod.file_name,
											},
										},
										{ preview: true },
									)
								}
							/>
						)}
					</div>
				);
			},
		};

		const eirColoumn = {
			title: t('EIR'),
			dataIndex: 'eir',
			CellRender({ style, className, record, onRowClick }) {
				return (
					<div style={{ ...(style ?? {}) }} className={`${className}`}>
						{record.eir && (
							<Button
								type='text'
								icon={<DownloadOutlined />}
								onClick={() =>
									downloadFile(
										{
											path: {
												documentId: record.eir.id,
												orderId: record.order_id,
											},
											other: {
												fileName: record.eir.file_name,
											},
										},
										{ preview: true },
									)
								}
							/>
						)}
					</div>
				);
			},
		};

		switch (progressKey) {
			case ProgressMapKeysEnum.in_transit:
			case ProgressMapKeysEnum.arriving: {
				baseColumns.push(
					{
						title: t('SSL'),
						dataIndex: 'freight_released_at',
						CellRender({ style, className, record, onRowClick }) {
							return (
								<div
									style={{ ...(style ?? {}) }}
									className={`${className}`}
									onClick={() => onRowClick(record)}
								>
									<SslReleased
										actual_empty_returned_at={record?.actual_empty_returned_at}
										actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
										freight_released_at={record?.freight_released_at}
										port_of_discharge_eta={record?.port_of_discharge_eta}
									/>
								</div>
							);
						},
					},
					{
						dataIndex: 'customs_released_at',
						title: t('CSTM'),
						CellRender({ style, className, record, onRowClick }) {
							return (
								<div
									style={{ ...(style ?? {}) }}
									className={`${className}`}
									onClick={() => onRowClick(record)}
								>
									<CustomReleased
										actual_empty_returned_at={record?.actual_empty_returned_at}
										actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
										customs_released_at={record?.customs_released_at}
										port_of_discharge_eta={record?.port_of_discharge_eta}
									/>
								</div>
							);
						},
					},
					// {
					// 	dataIndex: 'terminal_released_at',
					// 	title: t('TRM'),
					// 	CellRender({ style, className, record, onRowClick }) {
					// 		return (
					// 			<div
					// 				style={{ ...(style ?? {}) }}
					// 				className={`${className}`}
					// 				onClick={() => onRowClick(record)}
					// 			>
					// 				<TerminalReleased
					// 					actual_empty_returned_at={record?.actual_empty_returned_at}
					// 					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					// 					terminal_released_at={record?.terminal_released_at}
					// 					port_of_discharge_eta={record?.port_of_discharge_eta}
					// 				/>
					// 			</div>
					// 		);
					// 	},
					// },
					// {
					// 	dataIndex: 'ready_to_pickup_at',
					// 	title: t('AVAL'),
					// 	CellRender({ style, className, record, onRowClick }) {
					// 		return (
					// 			<div
					// 				style={{ ...(style ?? {}) }}
					// 				className={`${className}`}
					// 				onClick={() => onRowClick(record)}
					// 			>
					// 				<AvaliableToPickup
					// 					actual_empty_returned_at={record?.actual_empty_returned_at}
					// 					actual_terminal_pickuped_at={record?.actual_terminal_pickuped_at}
					// 					ready_to_pickup_at={record?.ready_to_pickup_at}
					// 					port_of_discharge_eta={record?.port_of_discharge_eta}
					// 				/>
					// 			</div>
					// 		);
					// 	},
					// },
				);
				break;
			}
			case ProgressMapKeysEnum.avaliable: {
				baseColumns.push({
					title: t('Est. Pickup'),
					dataIndex: 'schedule_terminal_pickup_at',
				});
				break;
			}
			case ProgressMapKeysEnum.pickup: {
				baseColumns.push(
					{
						title: t('Est. Pickup'),
						dataIndex: 'schedule_terminal_pickup_at',
					},
					{
						title: t('Pickup'),
						dataIndex: 'actual_terminal_pickuped_at',
					},
					{
						title: t('Est. Delivery'),
						dataIndex: 'schedule_delivery_at',
					},
				);

				if (alwaysShowPodAndEir) {
					baseColumns.push(podColoumn, eirColoumn);
				}

				break;
			}
			case ProgressMapKeysEnum.delivered: {
				baseColumns.push(
					{
						title: t('Pickup'),
						dataIndex: 'actual_terminal_pickuped_at',
					},
					{
						title: t('Est. Delivery'),
						dataIndex: 'schedule_delivery_at',
					},
					{
						title: t('Delivery'),
						dataIndex: 'actual_delivered_at',
					},
				);

				baseColumns.push(podColoumn);

				if (alwaysShowPodAndEir) {
					baseColumns.push(eirColoumn);
				}

				break;
			}
			case ProgressMapKeysEnum.empty_returned:
			case ProgressMapKeysEnum.completed: {
				baseColumns.push(
					{
						title: t('Pickup'),
						dataIndex: 'actual_terminal_pickuped_at',
					},
					{
						title: t('Delivery'),
						dataIndex: 'actual_delivered_at',
					},
					{
						title: t('Return'),
						dataIndex: 'actual_empty_returned_at',
					},
					podColoumn,
					eirColoumn,
					// {
					// 	title: t('POD'),
					// 	dataIndex: 'pod',
					// 	CellRender({ style, className, record, onRowClick }) {
					// 		return (
					// 			<div style={{ ...(style ?? {}) }} className={`${className}`}>
					// 				{record.pod && (
					// 					<Button
					// 						type='text'
					// 						icon={<DownloadOutlined />}
					// 						onClick={() =>
					// 							downloadFile(
					// 								{
					// 									path: {
					// 										documentId: record.pod.id,
					// 										orderId: record.order_id,
					// 									},
					// 									other: {
					// 										fileName: record.pod.file_name,
					// 									},
					// 								},
					// 								{ preview: true },
					// 							)
					// 						}
					// 					/>
					// 				)}
					// 			</div>
					// 		);
					// 	},
					// },
					// {
					// 	title: t('EIR'),
					// 	dataIndex: 'eir',
					// 	CellRender({ style, className, record, onRowClick }) {
					// 		return (
					// 			<div style={{ ...(style ?? {}) }} className={`${className}`}>
					// 				{record.eir && (
					// 					<Button
					// 						type='text'
					// 						icon={<DownloadOutlined />}
					// 						onClick={() =>
					// 							downloadFile(
					// 								{
					// 									path: {
					// 										documentId: record.eir.id,
					// 										orderId: record.order_id,
					// 									},
					// 									other: {
					// 										fileName: record.eir.file_name,
					// 									},
					// 								},
					// 								{ preview: true },
					// 							)
					// 						}
					// 					/>
					// 				)}
					// 			</div>
					// 		);
					// 	},
					// },
				);
				break;
			}
			case ProgressMapKeysEnum.canceled: {
				break;
			}
			default: {
				throw new Error('Unknown progressKey');
			}
		}

		return baseColumns;
	}, [progressKey]);

	return (
		<>
			<Spin spinning={loading ?? false}>
				<Table
					columns={columns}
					dataSource={dataSource ?? []}
					className={`grow shrink overflow-scroll`}
					// className={`grow shrink overflow-scroll max-h-[76vh]`}
					commonCellStyle={{ borderBottom: '1px solid #e7e7e7' }}
					commonCellClass={` box-border p-[0_10px] flex items-center box-border h-[44px]`}
					commonHeaderClass={`foot-note-1-m text-[#777777]`}
					commonBodyClass={`foot-note-1-r text-black whitespace-nowrap`}
					onNextPage={onNextPage}
					onRowClick={(record) => {
						setSelectedRecord(record);
						openContainerDetailModal();
					}}
				/>
			</Spin>
			{openingContainerDetailModal && (
				<ContainerDetailModal
					mask={true}
					projectId={projectId}
					orderId={selectedRecord.order_id}
					containerId={selectedRecord.id}
					open={openingContainerDetailModal}
					onClose={() => {
						setSelectedRecord(null);
						closeContainerDetailModal();
					}}
				/>
			)}
		</>
	);
};
