import { getUser, getUserByCode, getUserByToken, getVerificationCode } from '@/services/apis';
import { makeAutoObservable, runInAction } from 'mobx';
import { unauthedNavigate } from '@/utils/unauthed-navigate';
import { searchRate } from '../search-rate/search-rate.context';
import accessTokenManager from './access-token-manager';
import { UserInfo } from './interface';

class UserContext {
	private userInfo: UserInfo | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	get type() {
		return this.userInfo?.type;
	}

	get prepayable() {
		return this.userInfo?.is_prepayable;
	}

	public async fetchUserInfo(email: string, password: string) {
		const { data } = await getUser({ data: { email, password } });
		const { access_token } = data;
		// 存 token
		accessTokenManager.setAccessToken(access_token);
		// 存 user info
		this.setUserInfo(data.user);
	}

	public async fetchUserInfoByCode(email: string, code: string) {
		const { data } = await getUserByCode(email, code);
		const { access_token } = data;
		// 存 token
		accessTokenManager.setAccessToken(access_token);
		// 存 user info
		this.setUserInfo(data.user);
	}

	public async getVerificationCode(email: string) {
		await getVerificationCode(email);
	}

	public async fetchUserInfoByAccessToken() {
		const { data } = await getUserByToken();
		// 存 user info
		this.setUserInfo(data.data);
	}

	public getUserInfo() {
		return this.userInfo;
	}

	public setUserInfo(userInfo: typeof this.userInfo) {
		runInAction(() => {
			this.userInfo = userInfo;
		});
		searchRate.remainingRateSearchLimit = userInfo?.remaining_rate_search_limit ?? 0;
		searchRate.rateSearchLimit = userInfo?.rate_search_limit ?? 0;
	}

	public isUserInfoEmpty() {
		return this.userInfo === null;
	}

	public logout() {
		accessTokenManager.clearAccessToken();
		this.userInfo = null;
		unauthedNavigate();
	}
}

export const userContext = new UserContext();
