import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import ShippingTruckIcon from '@/icons/shipping-truck-icon';
import { OrderFile } from '@/interfaces/file.interface';
import { Order } from '@/interfaces/order.interface';
import { useOrderContext } from '@/services/contexts';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import Actions from './actions';
import PreviewLabel from './preview-label';

interface DocumentsTableProps {
	order: Order;
}

const EmptyContent = () => (
	<Empty
		imageStyle={{ display: 'none' }}
		description={
			<div className='flex-center'>
				<ShippingTruckIcon className='text-grey-03' />
				<span className='h-4-m text-grey-02'>{t('There’s nothing here.')}</span>
			</div>
		}
	/>
);

export default observer(({ order }: DocumentsTableProps) => {
	const files = order.files;
	const containers = order.containers;

	const orderContext = useOrderContext();

	const columns: ColumnsType<OrderFile> = [
		{
			dataIndex: ['custom_properties', 'document_type'],
			title: t('Type'),
		},
		{
			dataIndex: ['file_name'],
			title: t('File Name'),
			sorter: (a, b) => a.file_name.localeCompare(b.file_name),
			render: (_, record) => <PreviewLabel order={order} record={record} />,
		},
		// {
		// 	key: 'container_ids',
		// 	title: t('File for'),
		// render(_, file) {
		// 	if (file.custom_properties.container_ids?.length === containers.length) {
		// 		return <span>All containers</span>
		// 	} else if (file.custom_properties.container_ids?.length === 1) {
		// 		// const id = file.custom_properties.container_ids[0];
		// 		// const targetContainer = containers.find(container => container.id == id);
		// 		return <span>{file.custom_properties.container_ids?.[0]}</span>
		// 	} else {
		// 		return <span>{file.custom_properties.container_ids?.length} {t('Containers')}</span>
		// 	}
		// }
		// },
		{
			dataIndex: ['created_at'],
			title: t('Update date'),
			sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1),
		},
		{
			dataIndex: ['size'],
			title: t('Size'),
		},
		{
			key: 'Action',
			title: t('Action'),
			render(_, record) {
				return (
					<Actions
						order={order}
						record={record}
						onDeleteSuccess={() => orderContext.refreshOrders()}
					/>
				);
			},
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={files ?? undefined}
			pagination={false}
			locale={{
				emptyText: <EmptyContent />,
			}}
		/>
	);
});
