import { Order } from '@/interfaces/order.interface';
import { useOrderContext } from '@/services/contexts';
import { observer } from 'mobx-react-lite';
import DocumentsHeader from './documents-header';
import DocumentsTable from './documents-table';

interface DocumentsTableProps {
	order: Order;
}

export default observer(({ order }: DocumentsTableProps) => {
	const orderContext = useOrderContext();

	return (
		<div>
			{/* header */}
			<DocumentsHeader order={order} fetchData={() => orderContext.refreshOrders()} />
			<DocumentsTable order={order} />
		</div>
	);
});
